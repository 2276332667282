import { Gap } from "components/common/Gap";
import { Custom } from "components/Products/Blog/Custom";
import { Marketing } from "components/Products/Blog/Marketing";
import { Solution } from "components/Products/Blog/Solution";

export const Blog = () => {
  return (
    <>
      <Marketing />
      <Gap />
      <Solution />
      <Gap />
      <Custom />
    </>
  );
};
