import InstaExample1 from "assets/image/InstaExample1.png";
import InstaExample2 from "assets/image/InstaExample2.png";
import InstaExample3 from "assets/image/InstaExample3.png";
import InstaExample4 from "assets/image/InstaExample4.png";
import InstaExample5 from "assets/image/InstaExample5.png";
import InstaExample6 from "assets/image/InstaExample6.png";
import InstaExample7 from "assets/image/InstaExample7.png";
import InstaExample8 from "assets/image/InstaExample8.png";
import InstaExample9 from "assets/image/InstaExample9.png";
import ReferenceText from "assets/image/ReferenceText.png";
import { MobileCarousel } from "components/common/MobileCarousel";

export const MobileReferenceExample = () => {
  return (
    <div className="h-1050">
      <div className="flex flex-col items-center justify-center">
        <h4 className="font-bold text-center mt-52 text-22 text-very-dark-gray leading-32">
          퍼포먼스 마케팅은<br></br> 이런 것 입니다.<br></br>검증된 성공
          레퍼런스
        </h4>
        <hr className="mt-24 w-342 border-light-gray border-1"></hr>
        <div>
          <div className="h-32 text-center mt-52 w-342 rounded-16 bg-light-gray text-very-dark-gray leading-32 text-18">
            뚜렷한 영향력을 광고에 담습니다.
          </div>
          <img
            className="h-64 mt-24 w-342"
            src={ReferenceText}
            alt="textImg"
          ></img>
        </div>
        <div className="w-342 my-52">
          <MobileCarousel images={CAROUSEL_IMAGES} className={"w-342"} />
        </div>
        <hr className="mt-10 w-342 border-light-gray border-1"></hr>
      </div>
    </div>
  );
};

const CAROUSEL_IMAGES = [
  InstaExample1,
  InstaExample2,
  InstaExample3,
  InstaExample4,
  InstaExample5,
  InstaExample6,
  InstaExample7,
  InstaExample8,
  InstaExample9,
];
