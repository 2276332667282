import Influencer from "assets/image/Influencer.png";
import InfluencerImg1 from "assets/image/InfluencerImg1.png";
import InfluencerImg2 from "assets/image/InfluencerImg2.png";
import InfluencerImg3 from "assets/image/InfluencerImg3.png";

export const InfluencerExample = () => {
  return (
    <div className="max-w-1200 w-[46.87vw] h-[51.68vw]">
      <h4 className="font-bold mt-[4.29vw] tracking-tightest text-[1.48vw] text-very-dark-gray leading-[2.34vw]">
        인플루언서 마케팅 플랫폼에 최상의 마케팅<br></br>성과를 내도록
        도와드립니다.
      </h4>
      <p className="mt-[0.31vw] tracking-tight leading-[1.17vw]">
        소셜라우더 체험단은 보다 적은 비용으로 다수의 효율을 얻고자 하는데
        특화되어있고,<br></br>콘텐츠에 전문화된 인플루언서들을 선정하여 효율의
        극대화를 얻을 수 있도록 자체보유한 플랫폼 입니다.
      </p>

      <hr className="w-full border-light-gray border-1 mt-[2.03vw]"></hr>
      <div className="flex justify-center w-full">
        <img className="mt-[2.03vw]" src={Influencer} alt="Influencer"></img>
      </div>
      <div className="flex gap-[0.78vw] mt-[1.79vw]">
        <img
          className="w-[15vw] h-[17.65vw]"
          src={InfluencerImg1}
          alt="Influencer"
        ></img>
        <img
          className="w-[15vw] h-[17.65vw]"
          src={InfluencerImg2}
          alt="Influencer"
        ></img>
        <img
          className="w-[15vw] h-[17.65vw]"
          src={InfluencerImg3}
          alt="Influencer"
        ></img>
      </div>
      <hr className="w-full border-light-gray border-1 mt-[2.03vw]"></hr>
    </div>
  );
};
