import BlogImg1 from "assets/image/BlogImg1.png";
import BlogImg2 from "assets/image/BlogImg2.png";
import BlogImg3 from "assets/image/BlogImg3.png";
import BlogImg4 from "assets/image/BlogImg4.png";

export const MobileMarketing = () => {
  return (
    <div className="flex flex-col items-center text-center h-1120">
      <h4 className="font-bold mt-82 text-22 leading-32 text-very-dark-gray">
        소셜라우더의 누적된 성과지표를<br></br>분석하고 타겟에 맞는 효과적인
        <br></br>블로그 마케팅을 지원합니다.
      </h4>

      <div className="flex flex-col tracking-tight gap-30">
        <div className={`${DIV_STYLE} mt-40`}>
          <div className={CONTENT_STYLE}>
            <img
              className="mt-10 w-80 h-80"
              src={BlogImg1}
              alt="BlogImg1"
            ></img>
            <h6 className="mt-10 font-bold text-22 text-very-dark-gray leading-40">
              성공하는 광고 전략
            </h6>
            <p className="leading-20 text-dark-gray text-14">
              폭 넓은 경험을 통해 브랜드에
              <br /> 최적화된 콘텐츠 전략기획
            </p>
          </div>
        </div>

        <div className={DIV_STYLE}>
          <div className={CONTENT_STYLE}>
            <img
              className="mt-12 w-80 h-80"
              src={BlogImg2}
              alt="BlogImg2"
            ></img>
            <h6 className="mt-10 font-bold text-22 text-very-dark-gray leading-40">
              블로그 인플루언서 확보
            </h6>
            <p className=" text-dark-gray text-14 leading-20">
              광고주 맞춤 타겟별 파워인플루언서 선정
            </p>
          </div>
        </div>

        <div className={DIV_STYLE}>
          <div className={CONTENT_STYLE}>
            <img
              className="mt-13 w-80 h-80"
              src={BlogImg3}
              alt="BlogImg3"
            ></img>
            <h6 className="mt-10 font-bold text-22 text-very-dark-gray leading-40">
              재밌고 안정감있는 리뷰
            </h6>
            <p className=" text-dark-gray text-14 leading-20">
              획된 가이드를 통한 신뢰성 높은 리뷰 생산
            </p>
          </div>
        </div>

        <div className={DIV_STYLE}>
          <div className={CONTENT_STYLE}>
            <img
              className="mt-12 w-80 h-80"
              src={BlogImg4}
              alt="BlogImg4"
            ></img>
            <h6 className="mt-10 font-bold text-22 text-very-dark-gray leading-40">
              투명한 성과 보고
            </h6>
            <p className=" text-dark-gray text-14 leading-20">
              검색 최적화를 통한
              <br /> 블로그 뷰 상위 노출 및 리포트제공
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const DIV_STYLE =
  "bg-white border-solid border-2 border-light-gray2 rounded-12 w-342 h-190";
const CONTENT_STYLE = "flex flex-col items-center justify-center text-center";
