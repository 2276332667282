import InfluencerInsta from "assets/image/InfluencerInsta.png";
import InfluencerInstaReels from "assets/image/InfluencerInstaReels.png";
import InfluencerNaver from "assets/image/InfluencerNaver.png";
import InfluencerNaverBlog from "assets/image/InfluencerNaverBlog.png";
import InfluencerShorts from "assets/image/InfluencerShorts.png";
import InfluencerTicTok from "assets/image/InfluencerTicTok.png";
import NaverIncluencer from "assets/image/NaverInfluencer.png";
import TicTok from "assets/image/TicTok.png";
import Instagram from "assets/svg/Instagram.svg";
import NaverBlog from "assets/svg/NaverBlog.svg";
import Youtube from "assets/svg/Youtube.svg";

export const Influencer = () => {
  return (
    <div className="max-w-1200 w-[46.87vw] h-[46.71vw]">
      <h4 className="font-bold text-[1.48vw] leading-[2.34vw] tracking-tightest mt-[4.29vw]">
        콘텐츠 홍보에 효과적인<br></br>최고의 인플루언서들로 자신있게 제안
        드립니다.
      </h4>
      <p className="mt-[0.31vw] leading-[1.17vw]">
        한번 계약으로 모든 리뷰 마케팅 서비스를 이용하실 수 있습니다.
      </p>
      <ul className="flex w-[46.87vw] my-[2.03vw]">
        {MARKETING_LIST.map((list, index) => (
          <li
            key={`marketing-list-${index}`}
            className={`flex flex-col items-center -ml-[3.12vw] hover:scale-143 hover:z-10 transition-transform duration-300 ${
              Z_INDEX[index]
            } ${index % 2 !== 0 && "mt-[1.95vw]"}`}
          >
            <div className="flex justify-center items-center w-[12.5vw] h-[21.09vw] bg-white shadow-blog rounded-30">
              <img
                className="w-[10.93vw] h-[19.53vw]"
                src={list.image}
                alt="img"
              />
            </div>
            <div className="flex items-center h-[1.25vw] gap-[0.46vw] mt-[2.2vw]">
              <img
                className="w-[1.25vw] h-[1.25vw]"
                src={list.icon}
                alt="icon"
              />
              <span>{list.title}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const MARKETING_LIST = [
  { image: InfluencerInstaReels, icon: Instagram, title: "인스타그램 릴스" },
  { image: InfluencerInsta, icon: Instagram, title: "인스타그램" },
  { image: InfluencerShorts, icon: Youtube, title: "유튜브 쇼츠" },
  { image: InfluencerNaverBlog, icon: NaverBlog, title: "네이버 블로그" },
  { image: InfluencerNaver, icon: NaverIncluencer, title: "네이버 인플루언서" },
  { image: InfluencerTicTok, icon: TicTok, title: "틱톡" },
];

const Z_INDEX = ["z-[5]", "z-[4]", "z-[3]", "z-[2]", "z-[1]"];
