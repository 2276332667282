import Choosing from "assets/svg/Choosing.svg";
import Data from "assets/svg/Data.svg";
import Perfection from "assets/svg/Perfection.svg";
import Popularity from "assets/svg/Popularity.svg";
import Success from "assets/svg/Success.svg";
import ThumsUp from "assets/svg/ThumbsUp2.svg";

export const ServiceInfo = () => {
  return (
    <div className="max-w-1200 w-[46.87vw] h-[31.99vw]">
      <h5 className="font-bold text-[1.48vw] text-very-dark-gray leading-[2.34vw] tracking-tightest mt-[4.29vw] mb-[1.44vw]">
        소셜라우더에서 최적의 인플루언서 마케팅 서비스
        <br /> 파워 인플루언서들을 이젠 손쉽게 만나보세요
      </h5>

      <div className="flex flex-wrap w-[46.87vw] gap-[0.93vw] tracking-tighter mb-[2.03vw]">
        <div className="bg-white border-solid border-2 border-light-gray2 rounded-[0.46vw] w-[15vw] h-[9.29vw]">
          <div className="flex ml-[0.93vw] mt-[1.44vw]">
            <h6 className="font-bold text-[0.93vw] text-very-dark-gray leading-[1.56vw] mr-[3.32vw]">
              캠페인 모집 <br /> 평균 1,304%
            </h6>
            <img
              className="w-[3.12vw] h-[3.12vw]"
              src={ThumsUp}
              alt="ThumsUp"
            ></img>
          </div>
          <p className="mb-[1.25vw] ml-[0.46vw] text-left text-dark-gray text-[0.62vw] leading-[1.17vw] mt-[1.13vw]">
            식품 평균 1,486%, 뷰티 1,461% 등<br /> 전 카테고리 평균 1,304%
            인플루언서가 모집됩니다.
          </p>
        </div>

        <div className="bg-white border-solid border-2 border-light-gray2 rounded-[0.46vw] w-[15vw] h-[9.29vw]">
          <div className="flex ml-[0.93vw] mt-[1.44vw]">
            <h6 className="font-bold text-[0.93vw] text-very-dark-gray leading-[1.56vw] mr-[2.96vw]">
              인플루언서 <br />
              업계 최다 보유
            </h6>
            <img
              className="w-[3.12vw] h-[3.12vw]"
              src={Popularity}
              alt="Popularity"
            ></img>
          </div>
          <p className="mb-[1.25vw] ml-[0.93vw] text-left text-dark-gray text-[0.62vw] leading-[1.17vw] mt-[1.13vw]">
            국내외 최다인 100만명 이상의
            <br /> 인플루언서와 함께 할 수 있습니다.
          </p>
        </div>

        <div className="bg-white border-solid border-2 border-light-gray2 rounded-[0.46vw] w-[15vw] h-[9.29vw]">
          <div className="flex ml-[0.93vw] mt-[1.44vw]">
            <h6 className="font-bold text-[0.93vw] text-very-dark-gray leading-[1.56vw] mr-[3.2vw]">
              13년 업계 <br />
              최다 성공사례
            </h6>
            <img
              className="w-[3.12vw] h-[3.12vw]"
              src={Success}
              alt="Success"
            ></img>
          </div>
          <p className="mb-[1.25vw] ml-[0.93vw] text-left text-dark-gray text-[0.62vw] leading-[1.17vw] mt-[1.13vw]">
            2011~현재까지 축적된 성공사례의 <br />
            노하우로 1:1 맞춤 컨설팅해 드립니다.
          </p>
        </div>

        <div className="bg-white border-solid border-2 border-light-gray2 rounded-[0.46vw] w-[15vw] h-[9.29vw]">
          <div className="flex ml-[0.93vw] mt-[1.44vw]">
            <h6 className="mr-[1.25vw] font-bold text-[0.93vw] text-very-dark-gray leading-[1.56vw]">
              콘텐츠 퀄리티 보장
              <br /> 원고검수형 시스템
            </h6>
            <img
              className="w-[3.12vw] h-[3.12vw]"
              src={Perfection}
              alt="Perfection"
            ></img>
          </div>
          <p className="mb-[1.25vw] ml-[0.93vw] text-left text-dark-gray text-[0.62vw] leading-[1.17vw] mt-[1.13vw]">
            체계적인 원고 검수 시스템을 통해 <br /> 콘텐츠의 양과 질을 동시에
            보장 해 드립니다.
          </p>
        </div>

        <div className="bg-white border-solid border-2 border-light-gray2 rounded-[0.46vw] w-[15vw] h-[9.29vw]">
          <div className="flex ml-[0.93vw] mt-[1.44vw]">
            <h6 className="font-bold text-[0.93vw] text-very-dark-gray leading-[1.56vw] mr-[2.34vw]">
              인플루언서 직접 <br />
              선정 시스템
            </h6>
            <img
              className="w-[3.12vw] h-[3.12vw]"
              src={Choosing}
              alt="Choosing"
            ></img>
          </div>
          <p className="mb-[1.25vw] ml-[0.93vw] text-left text-dark-gray text-[0.62vw] leading-[1.17vw] mt-[1.13vw]">
            정해진 인플루언서가 아닌 직접 <br />
            인플루언서를 선택해서 진행할 수 있습니다.
          </p>
        </div>

        <div className="bg-white border-solid border-2 border-light-gray2 rounded-[0.46vw] w-[15vw] h-[9.29vw]">
          <div className="flex ml-[0.93vw] mt-[1.44vw]">
            <h6 className="font-bold text-[0.93vw] text-very-dark-gray leading-[1.56vw] mr-[2.34vw]">
              광고주 전용 <br />
              결과보고서 제공
            </h6>
            <img className="w-[3.12vw] h-[3.12vw]" src={Data} alt="Data"></img>
          </div>
          <p className="mb-[1.25vw] ml-[0.93vw] text-left text-dark-gray text-[0.62vw] leading-[1.17vw] mt-[1.13vw]">
            날짜별 view 영역 노출 게재 화면과
            <br /> 실시간 결과 데이터를 제공합니다.
          </p>
        </div>
      </div>
    </div>
  );
};
