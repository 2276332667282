import character from "assets/image/Character.png";

export const Service = () => {
  return (
    <div className="flex items-start gap-[1.32vw] mt-[4.29vw] mb-[2.03vw] max-w-1200 w-[46.87vw]">
      <div className="flex flex-col justify-between mt-[5vw] mb-[4.37vw] leading-[1.17vw]">
        <p className="text-orange">
          블로그부터 인스타그램 릴스, 유튜브(쇼츠), 커뮤니티 바이럴까지
        </p>
        <p className="text-orange">소셜라우더의 경쟁력을 확인하세요</p>
        <h5 className="font-bold leading-[2.34vw] tracking-tightest text-[1.48vw] text-very-dark-gray my-[0.31vw]">
          소셜라우더만의 센스있는<br></br> 마케팅 기획과 함께
        </h5>
        <p className="text-black">
          소셜라우더는 광고주의 상황과 예산에 맞는 최적의 마케팅 구성을
          제안드립니다.
        </p>
        <p>
          최소한의 비용으로 최고의 효율을 낼 수 있도록 검증된 Database로
          최적화된
        </p>
        <p>서비스를 제공해드립니다.</p>
      </div>
      <img
        className="w-[21.16vw] h-[18.9vw]"
        src={character}
        alt="character"
      ></img>
    </div>
  );
};
