import Shorts from "assets/image/Shorts.png";
import TrendImg1 from "assets/image/TrendImg1.png";
import TrendImg2 from "assets/image/TrendImg2.png";
import TrendImg3 from "assets/image/TrendImg3.png";
import TrendImg4 from "assets/image/TrendImg4.png";

export const Trend = () => {
  return (
    <div className="max-w-1200 w-[46.87vw] h-[43.92vw]">
      <div className="flex">
        <div>
          <h4 className="font-bold text-[1.48vw] text-very-dark-gray leading-[2.34vw] tracking-tightest mt-[6.92vw] mr-[3.75vw]">
            유튜브 마케팅, 요즘은 쇼츠가 대세!
          </h4>
          <p className="leading-[1.17vw] tracking-tight w-[23.43vw] text-justify">
            소셜라우더는 시청전환 퍼포먼스가 나오는 바이럴 콘텐츠 전용 쇼츠를
            기획합니다. 시청 VIEW 전환을 위해서는 평범하고 획일적인 영상을
            만드는 것을 지양하고, SNS환경에 최적화 된 브랜드에 적합한 쇼츠,
            맞춤형 콘텐츠를 만들어내야 합니다. 빅데이터를 바탕으로 한 높은
            도달률의 인사이트를 제공하고 다양한 퍼포먼스 영상 으로 만족도 높은
            유튜브 마케팅을 여러분께 제시합니다.<br></br>마케팅 전략 분석 및
            콘텐츠에 대한 이해도가 높은 소셜라우더와 함께 하세요!
          </p>
        </div>
        <img
          className="w-[20.03vw] h-[15.91vw] mt-[3.55vw]"
          src={Shorts}
          alt="ShortsImg"
        />
      </div>
      <hr className="w-full border-light-gray border-1 mt-[2.03vw]"></hr>

      <h4 className="font-bold text-[1.48vw] text-very-dark-gray leading-[2.34vw] mt-[2.92vw]">
        유튜브가 숏츠를 밀어주는 이유!
        <br /> 엄청난 전파력으로 최고의 도달률을 보여줍니다.
      </h4>
      <div className="flex w-[46.87vw] gap-[0.93vw]">
        <div className={DIV_STYLE}>
          <div className={CONTENT_STYLE}>
            <img
              className="w-[3.12vw] h-[3.12vw] mt-[0.78vw]"
              src={TrendImg1}
              alt="TrendImg1"
            ></img>
            <h5 className={H5_STYLE}>데이터 분석을 통한 기획</h5>
            <p className={DESC_STYLE}>
              짧고 임팩트 있는 쇼츠로
              <br />
              효율적인 마케팅 제공
            </p>
          </div>
        </div>

        <div className={DIV_STYLE}>
          <div className={CONTENT_STYLE}>
            <img
              className="w-[3.12vw] h-[3.12vw] mt-[0.78vw]"
              src={TrendImg2}
              alt="TrendImg2"
            ></img>
            <h5 className={H5_STYLE}>인기 인플루언서 확보</h5>
            <p className={DESC_STYLE}>
              파워 인플루언서 다수 보유하여
              <br />
              다양한 타켓층 공략 가능
            </p>
          </div>
        </div>

        <div className={DIV_STYLE}>
          <div className={CONTENT_STYLE}>
            <img
              className="w-[3.12vw] h-[3.12vw] mt-[0.78vw]"
              src={TrendImg3}
              alt="TrendImg3"
            ></img>
            <h5 className={H5_STYLE}>고도의 전략 수집</h5>
            <p className={DESC_STYLE}>
              신뢰도 있는 실제 리뷰 영상을
              <br />
              활용한 홍보 전략
            </p>
          </div>
        </div>

        <div className={DIV_STYLE}>
          <div className={CONTENT_STYLE}>
            <img
              className="w-[3.12vw] h-[3.12vw] mt-[0.78vw]"
              src={TrendImg4}
              alt="TrendImg4"
            ></img>
            <h5 className={H5_STYLE}>모두가 이용하는 유튜브</h5>
            <p className={DESC_STYLE}>
              알고리즘 활용으로 확산에
              <br />
              적합한 트렌드 마케팅 실현
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const DIV_STYLE =
  "bg-white border-solid border-2 border-light-gray2 rounded-[0.46vw] w-[15vw] h-[9.29vw] mt-[1.44vw]";
const H5_STYLE =
  "font-bold text-[0.93vw] text-very-dark-gray leading-[1.56vw] mt-[0.58vw]";
const DESC_STYLE =
  " text-dark-gray text-[0.62vw] leading-[1.17vw] tracking-tight";
const CONTENT_STYLE = "flex flex-col items-center justify-between text-center";
