import Influencer from "assets/image/Influencer.png";
import InfluencerImg1 from "assets/image/InfluencerImg1.png";
import InfluencerImg2 from "assets/image/InfluencerImg2.png";
import InfluencerImg3 from "assets/image/InfluencerImg3.png";

export const MobileInfluencerExample = () => {
  return (
    <div className="h-1786">
      <div className="flex flex-col items-center justify-center text-center">
        <h4 className="font-bold mt-52 text-22 text-very-dark-gray leading-32">
          인플루언서 마케팅 플랫폼에<br></br> 최상의 마케팅성과를 내도록
          <br></br>
          도와드립니다.
        </h4>
        <p className="mt-12 tracking-tighter text-14 leading-24">
          소셜라우더 체험단은 보다 적은 비용으로 다수의 효율을<br></br> 얻고자
          하는데 특화되어있고,<br></br>콘텐츠에 전문화된 인플루언서들을 선정하여
          효율의<br></br>
          극대화를 얻을 수 있도록 자체보유한 플랫폼입니다.
        </p>
        <hr className="mt-24 w-342 border-light-gray border-1"></hr>
        <div className="flex justify-center">
          <img
            className="mt-24 w-342 h-45"
            src={Influencer}
            alt="Influencer"
          ></img>
        </div>
        <div className="flex flex-col gap-20 mt-20">
          <img
            className="w-344 h-414 rounded-12"
            src={InfluencerImg1}
            alt="Influencer"
          ></img>
          <img
            className="w-344 h-414 rounded-12"
            src={InfluencerImg2}
            alt="Influencer"
          ></img>
          <img
            className="w-344 h-414 rounded-12"
            src={InfluencerImg3}
            alt="Influencer"
          ></img>
        </div>
      </div>
    </div>
  );
};
