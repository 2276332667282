import Campaign1 from "assets/image/Campaign1.png";
import Campaign2 from "assets/image/Campaign2.png";
import Campaign3 from "assets/image/Campaign3.png";
import Campaign4 from "assets/image/Campaign4.png";
import Campaign5 from "assets/image/Campaign5.png";
import Campaign6 from "assets/image/Campaign6.png";
import { MultipleCarousel } from "components/common/MultipleCarousel";

export const Campaign = () => {
  return (
    <div className="flex flex-col items-center w-[46.87vw] h-[33.16vw]">
      <h4 className="text-[1.48vw] leading-[2.34vw] tracking-tightest mt-[4.29vw]">
        지금 소셜라우더에서 진행되고 있는
      </h4>
      <h4 className="font-bold text-[1.48vw] leading-[2.34vw] tracking-tightest">
        <span className="text-orange">다양한 분야</span>의 캠페인을 만나보세요.
      </h4>
      <p className="leading-[1.17vw] mt-[2.03vw]">
        현재 <span className="text-orange">219,776+ </span>건의 캠페인이
        진행되고 있습니다.
      </p>
      <div className="mt-[2.03vw] w-[46.87vw] h-[16.35vw]">
        <MultipleCarousel
          images={CAROUSEL_IMAGES}
          amount={CAROUSEL_AMOUNT}
          size="w-[8.75vw]"
        />
      </div>
    </div>
  );
};

const CAROUSEL_IMAGES = [
  Campaign1,
  Campaign2,
  Campaign3,
  Campaign4,
  Campaign5,
  Campaign6,
];

const CAROUSEL_AMOUNT = 5;
