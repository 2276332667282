import Logo from "assets/image/Logo_lg.png";
import MobileCamera from "assets/image/MobileCamera.png";

export const MobileFixed = () => {
  return (
    <div className="h-744 border-t-1 border-light-gray">
      <div className="flex flex-col items-center justify-center">
        <div className="text-center">
          <p className="mt-32 font-bold text-22 text-very-dark-gray leading-32">
            #영화 #드라마 #예능 #OTT
            <br /> #디지털콘텐츠마케팅 은
          </p>
          <div className="flex justify-center">
            <img
              className="w-248 h-110 mt-19 mb-18"
              src={Logo}
              alt="Logo"
            ></img>
          </div>
          <div className="w-342 h-100 bg-light-gray rounded-12 mb-18">
            <p className="p-20 text-16 leading-30 text-very-dark-gray">
              트렌드에 맞서 독보적인 콘텐츠로
              <span className="font-bold text-16 text-logo">
                Creative한 퍼포먼스
              </span>
              를 선사하겠습니다!
            </p>
          </div>
        </div>
        <img className="w-342 h-342" src={MobileCamera} alt="Camera"></img>
      </div>
    </div>
  );
};
