import CaretLeft from "assets/svg/CaretLeft.svg";
import CaretRight from "assets/svg/CaretRight.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import Shorts from "assets/svg/Shorts.svg";

export const Example = () => {
  const CustomNextArrow = (props: {
    className: string;
    onClick: () => void;
  }) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img className={className} src={CaretRight} alt="Right" />
      </div>
    );
  };

  const CustomPrevArrow = (props: {
    className: string;
    onClick: () => void;
  }) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img className={className} src={CaretLeft} alt="Left" />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <CustomNextArrow className={""} onClick={() => onclick} />,
    prevArrow: <CustomPrevArrow className={""} onClick={() => onclick} />,
    variableWidth: false,
  };

  return (
    <div className="max-w-1200 w-[46.87vw] h-[36vw] mt-[4.29vw]">
      <h4 className="font-bold text-[1.48vw] text-very-dark-gray leading-[2.34vw] tracking-tightest">
        소셜라우더는 크리에이티브와 퍼포먼스를 모두 갖춘<br></br>기획형 바이럴
        콘텐츠 솔루션을 제공합니다.
      </h4>
      <p className="mt-[0.31vw] leading-[1.17vw] text-orange">
        #기획 #제작 #분석 #관리 #광고 #SNS운영대행
      </p>
      <div className="flex items-center gap-[0.54vw] mt-[2.03vw]">
        <img className="w-[0.97vw] h-[1.21vw] " src={Shorts} alt="Shorts"></img>
        <span className="leading-[1.172vw]">Shorts</span>
      </div>
      <Slider {...settings} className="mt-[0.46vw]">
        {EXAMPLE_LIST.map((list, index) => (
          <div
            key={`card-img-${index}`}
            className="overflow-hidden w-[15.03vw] h-[23.43vw] rounded-12"
          >
            <iframe
              className="w-[15.03vw] h-[23.43vw] rounded-12"
              src={list.src}
              title={list.title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        ))}
      </Slider>
    </div>
  );
};

const EXAMPLE_LIST: { src: string; title: string }[] = [
  {
    src: "https://www.youtube.com/embed/lcZERad82wo",
    title:
      "다들 다이진의 브이로그 보고가!!#스즈메 #스즈메의문단속 #영화 #신카이마코토 #영화추천 #영화데이트 #영화명장면 #미디어캐슬 #다이진 #소타",
  },
  {
    src: "https://www.youtube.com/embed/9isQR9hmvHc",
    title: "인싸들이 쓴다는 모닝콜임 ㅋㅋㅋ #스즈메의문단속",
  },
  {
    src: "https://www.youtube.com/embed/fIRoUcaQmPI",
    title:
      "무슨 스파이더맨이 이렇게 많냐 ㄷㄷ #스파이더맨 #애스파 #스파이더맨:어크로스더유니버스 #소니 #소니픽쳐스 #마일스 #거미부대 #영화 #영화추천 #영화데이트 #개봉예정작",
  },
  {
    src: "https://www.youtube.com/embed/XF_IscfnkkE",
    title:
      "[극한직업] 이병헌 감독 신작 나왔다#드림 #영화 #박서준 #아이유 #이현우 #고창석 #이병헌감독 #영화추천 #영화데이트 #신작 #시사회 #개봉예정작",
  },
  {
    src: "https://www.youtube.com/embed/QD0S-HtUEzQ",
    title:
      "와 &#39;드림&#39;팀 그 잡채 ㄷㄷ #드림 #박서준 #이지은 #영화드림 #이병헌감독 #영화 #영화추천 #영화데이트  #추천영화 #배우 #연기 #아이유 #이병헌",
  },
  {
    src: "https://www.youtube.com/embed/6F3ophUokiE",
    title:
      "외교부가 이렇게 빡센 곳이었냐... 이 정도면 거의 특수부대인데.. #비공식작전 #shorts",
  },
];
