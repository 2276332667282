import InfluencerInsta from "assets/image/InfluencerInsta.png";
import InfluencerInstaReels from "assets/image/InfluencerInstaReels.png";
import InfluencerNaver from "assets/image/InfluencerNaver.png";
import InfluencerNaverBlog from "assets/image/InfluencerNaverBlog.png";
import InfluencerShorts from "assets/image/InfluencerShorts.png";
import InfluencerTicTok from "assets/image/InfluencerTicTok.png";
import NaverIncluencer from "assets/image/NaverInfluencer.png";
import TicTok from "assets/image/TicTok.png";
import Instagram from "assets/svg/Instagram.svg";
import NaverBlog from "assets/svg/NaverBlog.svg";
import Youtube from "assets/svg/Youtube.svg";

export const MobileInfluencer = () => {
  return (
    <div className="h-1341">
      <h4 className="font-bold text-center mt-52 text-22 leading-32 text-very-dark-gray">
        콘텐츠 홍보에 효과적인<br></br>최고의 인플루언서들로 자신있게<br></br>
        제안 드립니다.
      </h4>
      <p className="mt-12 tracking-tight text-center text-14 leading-24">
        한번 계약으로 모든 리뷰 마케팅 서비스를 이용하실 수<br></br>있습니다.
      </p>
      <ul className="flex flex-wrap gap-12 ml-24 w-342 mt-52">
        <li>
          <img
            className="w-165 h-300"
            src={InfluencerInstaReels}
            alt="reels"
          ></img>
          <div className="flex items-center justify-center gap-6 mt-24 mb-12">
            <img src={Instagram} alt="Instagram"></img>
            <span>인스타그램 릴스</span>
          </div>
        </li>
        <li>
          <img className="w-165 h-300" src={InfluencerInsta} alt="reels"></img>
          <div className="flex items-center justify-center gap-6 mt-24 mb-12">
            <img src={Instagram} alt="Instagram"></img>
            <span>인스타그램</span>
          </div>
        </li>
        <li>
          <img className="w-165 h-300" src={InfluencerShorts} alt="reels"></img>
          <div className="flex items-center justify-center gap-6 mt-24 mb-12">
            <img src={Youtube} alt="Youtube"></img>
            <span>유튜브 쇼츠</span>
          </div>
        </li>
        <li>
          <img
            className="w-165 h-300"
            src={InfluencerNaverBlog}
            alt="reels"
          ></img>
          <div className="flex items-center justify-center gap-6 mt-24 mb-12">
            <img src={NaverBlog} alt="NaverBlog"></img>
            <span>네이버 블로그</span>
          </div>
        </li>
        <li>
          <img className="w-165 h-300" src={InfluencerNaver} alt="reels"></img>
          <div className="flex items-center justify-center gap-6 mt-24 mb-12">
            <img className="w-32 h-32" src={NaverIncluencer} alt="Naver"></img>
            <span className="tracking-tight">네이버 인플루언서</span>
          </div>
        </li>
        <li>
          <img className="w-165 h-300" src={InfluencerTicTok} alt="reels"></img>
          <div className="flex items-center justify-center gap-6 mt-24 mb-12">
            <img className="w-32 h-32" src={TicTok} alt="Naver"></img>
            <span>틱톡</span>
          </div>
        </li>
      </ul>
    </div>
  );
};
