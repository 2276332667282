import SolutionImg1 from "assets/image/SolutionImg1.png";
import SolutionImg2 from "assets/image/SolutionImg2.png";
import SolutionImg3 from "assets/image/SolutionImg3.png";
import SolutionImg4 from "assets/image/SolutionImg4.png";
import SolutionImg5 from "assets/image/SolutionImg5.png";
import SolutionImg6 from "assets/image/SolutionImg6.png";
import SolutionImg7 from "assets/image/SolutionImg7.png";
import BothArrow from "assets/svg/BothArrow.svg";

export const Solution = () => {
  return (
    <div className="max-w-1200 w-[46.87vw] h-[54.41vw]">
      <h4 className="font-bold mt-[2.03vw] tracking-tightest text-[1.48vw] text-very-dark-gray leading-[2.34vw]">
        데이터 기반으로 기획부터 섭외 및 실행까지<br></br>전략 맞춤 솔루션
      </h4>
      <div className="flex justify-center w-full mt-[2.03vw]">
        <p className=" text-orange leading-[1.17vw]">DMAX (Driving Max)</p>
      </div>
      <hr className="w-full border-light-gray border-1 mt-[0.7vw]"></hr>
      <div className="flex justify-center tracking-tighter text-center gap-[5.58vw] mt-[2.03vw]  leading-[1.17vw]">
        <div className="flex flex-col items-center">
          <img
            className="w-[4.68vw] h-[4.68vw] mr-[0.78vw]"
            src={SolutionImg1}
            alt="img"
          ></img>
          <p className="mt-[0.7vw] text-orange">시장 분석</p>
          <p className="mt-[0.46vw]">
            업종, 타겟의 니즈, 인게이지먼트 등<br></br>고객 Buzz를 취합하여
            광고방향성 설정
          </p>
        </div>
        <div className="flex flex-col items-center">
          <img
            className="w-[4.68vw] h-[4.68vw] ml-[0.58vw]"
            src={SolutionImg2}
            alt="img"
          ></img>
          <p className="mt-[0.7vw] text-orange">미디어 믹스</p>
          <p className="mt-[0.46vw]">
            분석된 데이터를 활용하여 타겟의<br></br>이동동선 별 최적화된
            IMC미디어 선정
          </p>
        </div>
        <div className="flex flex-col items-center">
          <img
            className="w-[4.68vw] h-[4.68vw]"
            src={SolutionImg3}
            alt="img"
          ></img>
          <p className="mt-[0.7vw] text-orange">콘텐츠 제작</p>
          <p className="mt-[0.46vw]">
            핵심 셀링포인트를 도출하고 타겟 접촉<br></br>미디어에 최적화된
            데이터 기반의<br></br>크리에이티브 소재 제작
          </p>
        </div>
      </div>
      <div className="flex justify-center tracking-tighter text-center gap-[5.93vw] mt-[2.03vw]  leading-[1.17vw]">
        <div className="flex flex-col items-center">
          <img
            className="w-[4.68vw] h-[4.68vw]"
            src={SolutionImg4}
            alt="img"
          ></img>
          <p className="mt-[0.7vw] text-orange">퍼포먼스</p>
          <p className="mt-[0.46vw]">
            미디어에 최적화된 크리에이티브 소재를<br></br>활용한 A/B테스트, 구매
            타겟 최적화
          </p>
        </div>
        <div className="flex flex-col items-center">
          <img
            className="w-[4.68vw] h-[4.68vw] ml-[0.78vw]"
            src={SolutionImg5}
            alt="img"
          ></img>
          <p className="mt-[0.7vw] text-orange">성과 측정 및 분석</p>
          <p className="mt-[0.46vw]">
            광고로 유입된 고객의 제품 구매과정을<br></br>분석하고 인사이트
            도출을 통한 성과 극대화
          </p>
        </div>
      </div>
      <div className="flex justify-center gap-[0.93vw] tracking-tighter text-center leading-[1.17vw] mt-[4.29vw]">
        <div className="flex flex-col items-center w-[22.96vw]">
          <p className="text-orange">DART (Data mart)</p>
          <img className="mt-[0.7vw]" src={BothArrow} alt="arrow"></img>
          <img
            className="w-[4.68vw] h-[4.68vw] mt-[2.03vw]"
            src={SolutionImg6}
            alt="img"
          ></img>
          <p className="mt-[0.7vw] text-orange">고객 최적화</p>
          <p className="mt-[0.46vw]">
            구매, 이탈, 고객에 대한 데이터를 수집하고<br></br>고객관리를 통한
            브랜드 신뢰도 및 충성 고객 확보
          </p>
        </div>
        <div className="flex flex-col items-center w-[22.96vw]">
          <p className="text-orange">DPORT (Data Report)</p>
          <img className="mt-[0.7vw]" src={BothArrow} alt="arrow"></img>
          <img
            className="w-[4.68vw] h-[4.68vw] ml-[0.58vw] mt-[2.03vw]"
            src={SolutionImg7}
            alt="img"
          ></img>
          <p className="mt-[0.7vw] text-orange">성과 관리</p>
          <p className="mt-[0.46vw]">
            내, 외부적 광고 집행 전반의 성과를<br></br>분석하고 생산성 개선 및
            관리
          </p>
        </div>
      </div>
    </div>
  );
};
