import {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";

interface NavbarContextProps {
  activeMenu: string;
  setActiveMenu: Dispatch<SetStateAction<string>>;
}

const NavbarContext = createContext<NavbarContextProps>({
  activeMenu: "",
  setActiveMenu: () => {},
});

export const NavbarProvider = ({ children }: { children: React.ReactNode }) => {
  const [activeMenu, setActiveMenu] = useState("blog");

  const value = { activeMenu, setActiveMenu };

  return (
    <NavbarContext.Provider value={value}>{children}</NavbarContext.Provider>
  );
};

export const useNavbar = () => useContext(NavbarContext);
