import mockup from "assets/image/Mockup.png";

export const Mockup = () => {
  return (
    <div className="flex items-start max-w-1200 w-[46.87vw]">
      <div className="flex flex-col justify-between gap-[0.31vw] -mr-[0.11vw] mt-[7.96vw]">
        <p className="text-orange">광고주 전용 리포트 애드센터 제공</p>
        <h5 className="font-bold text-[1.48vw] text-very-dark-gray leading-[2.34vw] tracking-tightest">
          인플루언서 모집부터<br></br>결과 보고서까지 원스톱
        </h5>
      </div>
      <img
        className="w-[31.87vw] h-[22.57vw] mt-[4.29vw] mb-[2.14vw]"
        src={mockup}
        alt="mockup"
      ></img>
    </div>
  );
};
