// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-track > div > div {
  width: 1200px;
}

.slick-arrow,
.slick-arrow {
  width: 3.12vw;
  height: 3.12vw;
}

.slick-next {
  right: -50px;
}

.slick-prev {
  left: -50px;
}

.slick-next:before {
  content: "";
}

.slick-prev:before {
  content: "";
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Carousel.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;;EAEE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":[".slick-track > div > div {\n  width: 1200px;\n}\n\n.slick-arrow,\n.slick-arrow {\n  width: 3.12vw;\n  height: 3.12vw;\n}\n\n.slick-next {\n  right: -50px;\n}\n\n.slick-prev {\n  left: -50px;\n}\n\n.slick-next:before {\n  content: \"\";\n}\n\n.slick-prev:before {\n  content: \"\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
