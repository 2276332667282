import Root from "Root";
import { CampaignDetail } from "components/SocialLouder/Detail/CampaignDetail";
import { ReviewTalk } from "components/SocialLouder/Detail/ReviewTalk";
import { Join } from "components/SocialLouder/Login/Join";
import { JoinColored } from "components/SocialLouder/Login/JoinColored";
import { Login } from "components/SocialLouder/Login/Login";
import { IsMobileProvider } from "context/IsMobileContext";
import Company from "pages/Company";
import { Homepage } from "pages/Homepage";
import Products from "pages/Products";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <IsMobileProvider>
        <Root />
      </IsMobileProvider>
    ),
    errorElement: "Not Found",
    children: [
      {
        index: true,
        path: "/",
        element: <Company />,
      },
      {
        path: "/products",
        element: <Products />,
      },
      {
        path: "/homepage",
        element: <Homepage />,
      },
      {
        path: "/homepage/detail",
        element: <CampaignDetail />,
      },
      {
        path: "/homepage/talk",
        element: <ReviewTalk />,
      },
      {
        path: "/homepage/login",
        element: <Login />,
      },
      {
        path: "/homepage/join",
        element: <Join />,
      },
      {
        path: "/homepage/join-colored",
        element: <JoinColored />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
