import Logo from "assets/image/BLogo.png";
import Bubble from "assets/image/Bubble.png";
import BArrow from "assets/svg/BackArrow.svg";
import Search from "assets/svg/Search.svg";
import Lock from "assets/svg/Lock.svg";
import Sign from "assets/svg/Sign.svg";
import MovieDetail from "assets/image/MovieDetail.png";
import LeftArrow from "assets/svg/LeftArrow.svg";
import RightArrow from "assets/svg/RightArrow.svg";
import Clock from "assets/svg/Clock.svg";
import Heart from "assets/svg/RedHeart.svg";
import Mike from "assets/svg/Mike.svg";
import SocialLouder from "assets/image/SocialLouder.png";
import Kakao from "assets/image/Kakao.png";
import Ad from "assets/svg/Ad.svg";
import Thumb from "assets/svg/Thumb.svg";
import Insta from "assets/svg/Insta.svg";
import Facebook from "assets/svg/Facebook.svg";
import BlogIcon from "assets/image/BlogIcon.png";
import Profile1 from "assets/image/Profile1.png";
import Profile2 from "assets/image/Profile2.png";
import Profile3 from "assets/image/Profile3.png";
import Background from "assets/image/DetailBaneer.bg.png";
import Soundbanner from "assets/image/DetailBaneersound.png";
import Poster from "assets/image/DetailBannerPoster.png";
import Instargram from "assets/image/Instagram.png";
import Talk from "assets/image/SocialLouderbaneertalk.png";
import Caret from "assets/image/caretRight.png";

export const ReviewTalk = () => {
  return (
    <div className="flex flex-col justify-center font-noto">
      <div className="flex flex-col items-center justify-center font-noto whitespace-nowrap">
        <div className="flex items-center justify-center w-full max-w-1200 gap-26 mt-123 text-[#2c2c2c]">
          <img src={Logo} alt="Logo" />
          <div className="relative flex items-center justify-center">
            <img src={Bubble} className="absolute" alt="speech bubble" />
            <p className="relative text-14 ml-38 mr-22">
              공지: 쇼셜라우더가 확~ 달려졌습니다!
            </p>
          </div>
        </div>

        <div className="w-1200 flex justify-center items-center text-[#2c2c2c] cursor-pointer text-18 gap-34 h-100 py-35 mt-13">
          <div className="flex items-center justify-center">
            <p>전체</p>
            <img src={BArrow} alt="BackArrow" />
          </div>
          <div className="relative flex items-center">
            <div className="flex items-center justify-center">
              <p>진행 캠페인</p>
              <img src={BArrow} alt="BackArrow" />
            </div>
          </div>
          <p>마감 캠페인</p>
          <p>콘텐츠</p>
          <p>챌린지</p>
          <div className="flex items-center">
            <p>커뮤니티</p>
            <img src={BArrow} alt="BackArrow" />
          </div>

          <div className="flex items-center gap-22 text-14 text-[#606060]">
            <img src={Search} className="ml-240" alt="Search" />
            <div className="flex gap-7">
              <img src={Lock} alt="Lock" />
              <p>로그인</p>
            </div>
            <div className="flex gap-7">
              <img src={Sign} alt="Sign" />
              <p>회원가입</p>
            </div>
            <div className="ml-28 border-1 border-[#e6a454] rounded-4 h-32 text-black text-14 w-126 bg-[#feba69] text-center py-6">
              캠페인 오픈하기
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center w-full max-w-1200">
          <img
            className="absolute left-0 w-full h-533 top-328 "
            src={Background}
            alt="background"
          ></img>
          <div className="relative flex">
            <img className="w-320 h-456 mt-39" src={Poster} alt="Poster"></img>
            <div className="flex flex-col">
              <div className="flex ml-40 mt-85">
                <img
                  className="w-25 h-25"
                  src={Instargram}
                  alt="Instargram"
                ></img>
                <h1 className="mt-8 text-white ml-7 justify-content">
                  instargram
                </h1>
              </div>
              <p className="text-white mt-30 text-36 ml-36">
                영화 [너를만난여름] 기대리뷰
                <p />
                <p className="tracking-[2px] leading-27 text-white text-18 mt-20">
                  중국의 여류 소설가 바위창안(32·八月長安)의 ‘진화고 3부작’ 중
                  마지막 편인
                  <br />
                  ‘최호적아문’(最好的我們)을 원작으로 한 영화
                </p>
              </p>
              <div className="relative flex h-50 ml-36 mt-38">
                <p className="absolute z-30 top-14 left-95 text-16">
                  총 모집인원 <span className="mr-5 font-bold">5명 중</span>
                  <span className="font-bold text-red">115명</span> 지원
                </p>
                <div className="absolute z-0 flex">
                  <img
                    className="h-40 w-52"
                    src={Soundbanner}
                    alt="Soundbanner"
                  ></img>
                  <img className="w-773 h-46 ml-11" src={Talk} alt="Talk"></img>
                </div>
                <p className="absolute font-bold right-25 text-24 top-10 text-red">
                  2300
                  <span className="ml-3 text-16">%</span>
                </p>
                <div className="flex items-center font-bold h-93 mt-86">
                  <div className="flex flex-col items-center justify-center text-white bg-white/10 w-194 h-93 rounded-28">
                    <p className="leading-27 text-18">1.리뷰어 선정 </p>
                    <p className="leading-24 text-16">02.05~02.20</p>
                  </div>
                  <img className="w-23 h-23" src={Caret} alt="Caret"></img>
                  <div className="flex flex-col items-center justify-center bg-logo w-194 h-93 rounded-28">
                    <p className="leading-27 text-18">2.리뷰어 선정 </p>
                    <p className="leading-24 text-16">02.21</p>
                  </div>
                  <img className="w-23 h-23" src={Caret} alt="Caret"></img>
                  <div className="flex flex-col items-center justify-center text-white bg-white/10 w-194 h-93 rounded-28">
                    <p className="leading-27 text-18">3.리뷰등록 </p>
                    <p className="leading-24 text-16">02.22~02.24</p>
                  </div>
                  <img className="w-23 h-23" src={Caret} alt="Caret"></img>
                  <div className="flex flex-col items-center justify-center text-white bg-white/10 w-194 h-93 rounded-28">
                    <p className="leading-27 text-18">4.베스트 발표 </p>
                    <p className="leading-24 text-16">02.26</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* section2 */}
        <div className="flex justify-center w-full bg-light-gray3 h-637 pt-97">
          <div className="flex max-w-1200 gap-61">
            <div className="flex flex-col gap-16">
              <img src={MovieDetail} className="h-438" alt="MovieDetail" />
              <div className="flex justify-center gap-14">
                <img
                  src={LeftArrow}
                  className="cursor-pointer"
                  alt="LeftArrow"
                />
                <p className="text-18">1 / 3</p>
                <img
                  src={RightArrow}
                  className="cursor-pointer"
                  alt="RightArrow"
                />
              </div>
            </div>

            <div className="flex flex-col gap-39">
              <div className="flex flex-col items-end gap-23">
                <p className="text-gray4 text-18">
                  참여 남은시간 ( 총 모집인원 5명 중 115명 지원 중)
                </p>
                <div className="flex items-center justify-center gap-10">
                  <img src={Clock} alt="Clock" />
                  <p className="font-bold text-red text-40">11시간 04분 09초</p>
                </div>
              </div>
              <div className="flex gap-16">
                <div className="flex items-center justify-center cursor-pointer gap-14 w-134 h-84 border-1 rounded-20 border-light-gray5">
                  <img src={Heart} alt="Heart" />
                  <p className="text-20 text-light-gray6">208</p>
                </div>
                <div className="flex items-center justify-center w-518 h-84 rounded-20 bg-logo border-1 border-orange4">
                  <img src={Mike} alt="Mike" />
                  <p className="font-bold cursor-pointer text-24 ml-7">
                    리뷰어 신청하기
                  </p>
                  <p className="ml-20 text-20">( 남은시간 11시간 04분 09초 )</p>
                </div>
              </div>
              <div className="bg-light-gray10 w-668 h-196 rounded-20 text-14 pt-38 pl-38 leading-24">
                <p>캠페인 안내</p>
                <p className="text-gray5">
                  제공된 가이드라인과 컨텐츠를 활용한 자유로운 리뷰 진행
                  <br /> * 리뷰어 선정 후 취소 요청 시 패널티가 자동으로
                  부여됩니다. (-5000P)
                  <br /> * 기간 내 리뷰 미등록 및 가이드라인 미준수 시 패널티가
                  자동으로 부여됩니다. (-5000P) <br />* 캠페인 신청 후 선정 시
                  취소가 불가능합니다. 이 점 숙지하시어 신중한 신청
                  부탁드립니다.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* section3 */}
        <div className="flex w-1200 pt-52">
          <div className="cursor-pointer border-b-1 text-15 py-18 px-55 border-light-gray7">
            캠페인 상세정보
          </div>

          <div className="flex gap-5 cursor-pointer border-r-1 border-l-1 border-t-1 text-15 py-18 px-55 border-light-gray7">
            신청자 한줄톡! <p className="text-light-gray11">(115/5)</p>
          </div>
          <div className="border-b-1 border-light-gray7 w-793"></div>
        </div>
        <div className="flex flex-col gap-16 pt-65">
          <div className="flex shadow-talk w-1130 h-119 rounded-20 py-25 pl-29 gap-15">
            <img src={Profile1} className="h-70" alt="ProfileImage" />
            <div className="flex flex-col gap-10">
              <p className="font-bold text-16">
                씨네소울
                <span className="font-medium text-14 text-light-gray8">
                  ㅣ2024.03.7 (14시간전)
                </span>
              </p>
              <p className="tracking-tight text-14 leading-20">
                해외입양아의 고립감과 소속감에 대한 경계를 어떻게 연출했는지
                궁금하네요. 감독의 자전적 영향을 받은 작품이라고 하는데,
                해외입양아가 느끼는 정체성 혼란과 복잡한 감정을
                <br /> 스크린에서 어떻게 표출해냈을지 궁금합니다.
              </p>
            </div>
          </div>

          <div className="flex shadow-talk w-1130 h-119 rounded-20 py-25 pl-29 gap-15">
            <img src={Profile2} className="h-70" alt="ProfileImage" />
            <div className="flex flex-col gap-10">
              <p className="font-bold text-16">
                브이네
                <span className="font-medium text-14 text-light-gray8">
                  ㅣ2024.03.7 (15시간전)
                </span>
              </p>
              <p className="tracking-tight text-14 leading-20">신청합니다.</p>
            </div>
          </div>

          <div className="flex shadow-talk w-1130 h-119 rounded-20 py-25 pl-29 gap-15">
            <img src={Profile3} className="h-70" alt="ProfileImage" />
            <div className="flex flex-col gap-7">
              <p className="font-bold text-16">
                괴수의 왕
                <span className="font-medium text-14 text-light-gray8">
                  ㅣ2024.03.7 (16시간전)
                </span>
              </p>
              <p className="tracking-tight text-14 leading-20">
                정말로 잘 쓸 수 있어요~
                <br /> 선정된다면 끝내주게 한번 써보겠습니다.
                <br />
                부탁드려요!! 플리즈~~~~
              </p>
            </div>
          </div>

          <div className="flex shadow-talk w-1130 h-119 rounded-20 py-25 pl-29 gap-15">
            <img src={Profile1} className="h-70" alt="ProfileImage" />
            <div className="flex flex-col gap-10">
              <p className="font-bold text-16">
                씨네소울
                <span className="font-medium text-14 text-light-gray8">
                  ㅣ2024.03.7 (14시간전)
                </span>
              </p>
              <p className="tracking-tight text-14 leading-20">
                해외입양아의 고립감과 소속감에 대한 경계를 어떻게 연출했는지
                궁금하네요. 감독의 자전적 영향을 받은 작품이라고 하는데,
                해외입양아가 느끼는 정체성 혼란과 복잡한 감정을
                <br /> 스크린에서 어떻게 표출해냈을지 궁금합니다.
              </p>
            </div>
          </div>

          <div className="flex shadow-talk w-1130 h-119 rounded-20 py-25 pl-29 gap-15">
            <img src={Profile2} className="h-70" alt="ProfileImage" />
            <div className="flex flex-col gap-10">
              <p className="font-bold text-16">
                브이네
                <span className="font-medium text-14 text-light-gray8">
                  ㅣ2024.03.7 (15시간전)
                </span>
              </p>
              <p className="tracking-tight text-14 leading-20">신청합니다.</p>
            </div>
          </div>

          <div className="flex shadow-talk w-1130 h-119 rounded-20 py-25 pl-29 gap-15">
            <img src={Profile3} className="h-70" alt="ProfileImage" />
            <div className="flex flex-col gap-7">
              <p className="font-bold text-16">
                괴수의 왕
                <span className="font-medium text-14 text-light-gray8">
                  ㅣ2024.03.7 (16시간전)
                </span>
              </p>
              <p className="tracking-tight text-14 leading-20">
                정말로 잘 쓸 수 있어요~
                <br /> 선정된다면 끝내주게 한번 써보겠습니다.
                <br />
                부탁드려요!! 플리즈~~~~
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center cursor-pointer mb-65 mt-62 text-18 bg-light-gray w-260 h-52 rounded-43">
              더보기
            </div>
          </div>
        </div>
      </div>
      {/* section4 */}

      <div className="w-full">
        <div className="flex justify-center bg-footer h-480">
          <div className="flex items-center justify-between w-1200">
            <div className="flex flex-col">
              <img src={SocialLouder} className="w-140" alt="SocialLouder" />
              <div className="flex text-white mt-43 ">
                <img src={Kakao} alt="Kakao" />
                <p className="text-16 ml-7">
                  쇼셜라우더 카카오톡 운영시간: 10:00~17:00 (주말 공휴일 휴무)
                </p>
              </div>
              <p className="mt-32 tracking-wider text-14 text-light-gray7 leading-23">
                서울특별시 강남구 도산대로59길 26, 2층(청담동, 히든힐하우스)
                <br />
                help@sociallouder.co.kr
              </p>
              <p className="mt-10 text-14 text-light-gray7 leading-23">
                사업자등록번호 : 658-88-02426 [사업자확인]
                <br /> 상호 : 주식회사 소셜라우더 | 대표자 : 최동인, 손나래
                <br />
                개인정보보호책임자 : 김하늘
              </p>
            </div>

            <div className="flex flex-col mt-105 text-15">
              <div className="flex items-center ml-40">
                <img src={Ad} alt="Ad" />
                <p className="text-white ml-7">쇼셜라우더 광고 문의</p>
                <div className="h-40 py-10 text-center cursor-pointer ml-86 border-1 border-black4 w-210 bg-black3 text-logo rounded-8">
                  플랫폼 제휴 문의하기
                </div>
              </div>

              <div className="flex items-center justify-center ml-37">
                <img src={Thumb} className="mt-18" alt="Thumb" />
                <p className="ml-5 text-white mt-18 mr-25">
                  맞춤형 인플루언서 마케팅 문의
                </p>
                <div className="h-40 py-10 text-center cursor-pointer mt-22 border-1 border-black4 w-210 bg-black3 text-logo rounded-8">
                  맞춤 인플루언서 마케팅 문의
                </div>
              </div>
              <div className="flex items-center cursor-pointer border-y-1 border-light-gray8 py-14 mt-14 text-light-gray7">
                <p className="mr-60">
                  소셜라우더 BIZ 광고 보기 ㅣ 쇼설라우더 제안서 보기
                </p>
                <img src={Insta} className="w-27 mr-9" alt="Insta" />
                <img src={Facebook} className="w-27 mr-9" alt="Facebook" />
                <img src={BlogIcon} className="w-27" alt="BlogIcon" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-light-gray8 text-14 text-light-gray9">
          <div className="flex justify-between m-auto h-81 pt-30 w-1200">
            <p>
              Copyright © 2021 소셜라우더 - 영화SNS 블로그체험단 Inc. All rights
              reserved
            </p>
            <p className="cursor-pointer">
              회사소개 ㅣ 이용약관 ㅣ 개인정보처리방침
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
