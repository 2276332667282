import SocialLouderBg from "assets/image/SocialBackBg.png";
import SocialLouderLogo from "assets/image/SocialLoudeBlackLogo.png";
import BussinessLogo from "assets/svg/BussinessWhite.svg";
import Mic from "assets/svg/MicWhite.svg";
import RightArrow2 from "assets/svg/RightArrow2.svg";
export const JoinColored = () => {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen bg-logo font-noto">
      <img
        className="absolute w-full h-full"
        src={SocialLouderBg}
        alt="SocialLouderBg"
      ></img>
      <div className="relative bg-white shadow-login w-890 h-580 rounded-10">
        <img
          className="mt-35 ml-41"
          src={SocialLouderLogo}
          alt="SocialLouderLogd"
        ></img>
        <h2 className="mb-4 font-black text-center font-noto mt-26 text-36">
          Join Membership
        </h2>
        <p className="mt-20 text-center font-14 font-noto">
          타입을 선택하시고 소셜라우더의 회원가입을 진행하세요.
        </p>
        <div className="flex items-center justify-center">
          <button className="flex items-center gap-16 border-solid bg-blue border-blue2 border-1 rounded-10 mt-44 w-563 h-80">
            <img
              className="pl-28"
              src={BussinessLogo}
              alt="BussonessLogo"
            ></img>
            <div className="flex-col">
              <p className="flex font-black text-white text-16 font-noto">
                비즈니스
              </p>
              <p className="mt-5 text-white text-13 font-noto">
                캠페인을 오픈하는 사업자 회원이시면 선택해주세요.
              </p>
            </div>
          </button>
        </div>
        <div className="flex justify-center">
          <button className="flex items-center gap-16 mt-8 border-solid border-1 border-orange6 rounded-10 w-563 h-80 bg-orange5">
            <img className="pl-28" src={Mic} alt="Mic"></img>
            <div className="flex-col">
              <p className="flex font-bold text-white font-noto text-16">
                인플루언서
              </p>
              <p className="mt-5 text-white font-noto text-13 ">
                캠페인을 신청하고 리뷰를 작성하는 일반 회원이시면 선택해 주세요.
              </p>
            </div>
          </button>
        </div>
        <div className="flex items-center justify-center mt-44">
          <button className="flex items-center border-solid border-brown border-1 w-563 h-50 rounded-10 bg-logo">
            <p className="font-bold text-black ml-253 text-16 font-noto">
              다음단계
            </p>
            <img className="ml-200" src={RightArrow2}></img>
          </button>
        </div>
      </div>
    </div>
  );
};
