import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

interface Props {
  images: string[];
}

export const MobileMultipleCarousel = ({ images }: Props) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    centerPadding: "12px",
    swipeToSlide: true,
    variableWidth: false,
  };

  return (
    <Slider {...settings}>
      {images.map((img, index) => (
        <div key={`card-img-${index}`} className="relative">
          <img className="w-165 h-299" src={img} alt={`carouselImg-${index}`} />
        </div>
      ))}
    </Slider>
  );
};
