import BlogExample1 from "assets/image/BlogExample1.png";
import BlogExample2 from "assets/image/BlogExample2.png";
import BlogExample3 from "assets/image/BlogExample3.png";
import BlogExample4 from "assets/image/BlogExample4.png";
import BlogExample5 from "assets/image/BlogExample5.png";
import BlogExample6 from "assets/image/BlogExample6.png";
import BlogExample7 from "assets/image/BlogExample7.png";
import BlogExample8 from "assets/image/BlogExample8.png";
import BlogExample9 from "assets/image/BlogExample9.png";
import { MobileCarousel } from "components/common/MobileCarousel";

export const MobileCustom = () => {
  return (
    <div>
      <p className="text-center text-orange text-14 leading-24 mt-52">
        고객의 니즈에 맞는 철저한 가이드라인과 더불어<br></br>맞춤 형 타겟을
        선별하여 최고의 효율을 약속합니다.
      </p>
      <h4 className="mt-12 font-bold text-center text-22 leading-32 text-very-dark-gray">
        최적의 효율을 내는 맞춤<br></br>블로그 마케팅을 제공합니다.
      </h4>
      <p className="mt-12 text-center text-14 leading-30">
        네이버 블로그는 신뢰성이 높은 대중적인<br></br>마케팅 채널입니다.
        정보성을 갖춘 리뷰 중심의<br></br>블로그 인플루언서 마케팅으로 우리는
        최고의<br></br>팀워크로 가장 비범한 결과를 만듭니다.
      </p>
      <div className="w-342 my-52">
        <MobileCarousel images={BLOG_IMAGES} className={"w-342 h-657"} />
      </div>
    </div>
  );
};

const BLOG_IMAGES = [
  BlogExample1,
  BlogExample2,
  BlogExample3,
  BlogExample4,
  BlogExample5,
  BlogExample6,
  BlogExample7,
  BlogExample8,
  BlogExample9,
];
