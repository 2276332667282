import Instagram from "assets/image/Instagram.png";
import Naver from "assets/image/Naver.png";
import Youtube from "assets/image/Youtube.png";

export const Banner = () => {
  return (
    <div className="flex flex-col items-center w-screen bg-black h-[22.42vw]">
      <p className="mb-[0.15vw text-orange mt-[2.03vw]">
        소셜라우더 BIZ 에서는
      </p>
      <h5 className="font-bold text-white text-[1.48vw] leading-[2.34vw] tracking-tightest">
        어떤 채널의 인플루언서도 문제없이 매칭해드립니다.
      </h5>
      <div className="flex mt-[4.29vw]">
        <div className="flex flex-col items-center mr-[4.72vw]">
          <img
            className="w-[3.90vw] h-[3.90vw]"
            src={Instagram}
            alt="character"
          ></img>
          <h6 className="font-bold text-white my-[1.17vw] text-[0.93vw]">
            INSTAGRAM
          </h6>
          <p className="text-center text-white text-[0.62vw] leading-[1.17vw]">
            브랜드 핏에 꼭 맞는 인스타그램 인플루언서를
            <br />
            섭외하여 이미지, 영상, 릴스, 스토리 등<br />
            인스타그램의 모든 콘텐츠 형식으로 캠페인 진행
          </p>
        </div>
        <div className="flex flex-col items-center mr-[4.72vw]">
          <img
            className="w-[3.90vw] h-[3.906vw]"
            src={Naver}
            alt="character"
          ></img>
          <h6 className="font-bold text-white my-[1.17vw] text-[0.93vw]">
            NAVER INFLUENCER
          </h6>
          <p className="text-center text-white text-[0.62vw] leading-[1.17vw]">
            네이버에서 선정한
            <br /> 분야별 전문 인플루언서로만 진행되는 캠페인으로
            <br /> 약 9,500명의 인플루언서를 선택하여 진행
          </p>
        </div>
        <div className="flex flex-col items-center">
          <img
            className="w-[3.90vw] h-[3.906vw]"
            src={Youtube}
            alt="character"
          ></img>
          <h6 className="font-bold text-white my-[1.17vw] text-[0.93vw]">
            YOUTUBE
          </h6>
          <p className="text-center text-white text-[0.62vw] leading-[1.17vw]">
            브랜드 핏에 맞는 유튜버 or 구독자 1만명 이상의
            <br /> 유튜버가 보장되고, 캠페인 미션에 따라
            <br /> 브랜디드, PPL타입 제작 가능
          </p>
        </div>
      </div>
    </div>
  );
};
