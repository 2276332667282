import { Trend } from "components/Products/Youtube/Trend";
import { Gap } from "components/common/Gap";
import { Example } from "./Example";
import { Process } from "./Process";
import { Solution } from "./Solution";

export const Youtube = () => {
  return (
    <>
      <Trend />
      <Gap />
      <Process />
      <Gap />
      <Example />
      <Gap />
      <Solution />
    </>
  );
};
