import Logo from "assets/image/BLogo.png";
import Bubble from "assets/image/Bubble.png";
import BArrow from "assets/svg/BackArrow.svg";
import Search from "assets/svg/Search.svg";
import Lock from "assets/svg/Lock.svg";
import Sign from "assets/svg/Sign.svg";
import MovieDetail from "assets/image/MovieDetail.png";
import LeftArrow from "assets/svg/LeftArrow.svg";
import RightArrow from "assets/svg/RightArrow.svg";
import Clock from "assets/svg/Clock.svg";
import Heart from "assets/svg/GrayHeart.svg";
import Mike from "assets/svg/Mike.svg";
import SocialLouder from "assets/image/SocialLouder.png";
import Kakao from "assets/image/Kakao.png";
import Ad from "assets/svg/Ad.svg";
import Thumb from "assets/svg/Thumb.svg";
import Insta from "assets/svg/Insta.svg";
import Facebook from "assets/svg/Facebook.svg";
import BlogIcon from "assets/image/BlogIcon.png";
import Background from "assets/image/DetailBaneer.bg.png";
import Soundbanner from "assets/image/DetailBaneersound.png";
import Poster from "assets/image/DetailBannerPoster.png";
import Instargram from "assets/image/Instagram.png";
import Talk from "assets/image/SocialLouderbaneertalk.png";
import Caret from "assets/image/caretRight.png";
import Notice from "assets/image/Notice.png";

export const CampaignDetail = () => {
  return (
    <div className="flex flex-col items-center w-full font-noto">
      <div className="flex flex-col items-center justify-center w-full font-noto max-w-1200 whitespace-nowrap">
        <div className="flex items-center justify-center gap-26 mt-123 text-[#2c2c2c]">
          <img src={Logo} alt="Logo" />
          <div className="relative flex items-center justify-center">
            <img src={Bubble} className="absolute" alt="speech bubble" />
            <p className="relative text-14 ml-38 mr-22">
              공지: 쇼셜라우더가 확~ 달려졌습니다!
            </p>
          </div>
        </div>

        <div className="w-1200 flex justify-center items-center text-[#2c2c2c] cursor-pointer text-18 gap-34 h-100 py-35 mt-13">
          <div className="flex items-center justify-center">
            <p>전체</p>
            <img src={BArrow} alt="BackArrow" />
          </div>
          <div className="relative flex items-center">
            <div className="flex items-center justify-center">
              <p>진행 캠페인</p>
              <img src={BArrow} alt="BackArrow" />
            </div>
          </div>
          <p>마감 캠페인</p>
          <p>콘텐츠</p>
          <p>챌린지</p>
          <div className="flex items-center">
            <p>커뮤니티</p>
            <img src={BArrow} alt="BackArrow" />
          </div>

          <div className="flex items-center gap-22 text-14 text-[#606060]">
            <img src={Search} className="ml-240" alt="Search" />
            <div className="flex gap-7">
              <img src={Lock} alt="Lock" />
              <p>로그인</p>
            </div>
            <div className="flex gap-7">
              <img src={Sign} alt="Sign" />
              <p>회원가입</p>
            </div>
            <div className="ml-28 border-1 border-[#e6a454] rounded-4 h-32 text-black text-14 w-126 bg-[#feba69] text-center py-6">
              캠페인 오픈하기
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center w-full max-w-1200">
          <img
            className="absolute left-0 w-full h-533 top-328 "
            src={Background}
            alt="background"
          ></img>
          <div className="relative flex">
            <img className="w-320 h-456 mt-39" src={Poster} alt="Poster"></img>
            <div className="flex flex-col">
              <div className="flex ml-40 mt-85">
                <img
                  className="w-25 h-25"
                  src={Instargram}
                  alt="Instargram"
                ></img>
                <h1 className="mt-8 text-white ml-7 justify-content">
                  instargram
                </h1>
              </div>
              <p className="text-white mt-30 text-36 ml-36">
                영화 [너를만난여름] 기대리뷰
                <p />
                <p className="tracking-[2px] leading-27 text-white text-18 mt-20">
                  중국의 여류 소설가 바위창안(32·八月長安)의 ‘진화고 3부작’ 중
                  마지막 편인
                  <br />
                  ‘최호적아문’(最好的我們)을 원작으로 한 영화
                </p>
              </p>

              <div className="relative flex h-50 ml-36 mt-38">
                <p className="absolute z-30 top-14 left-95 text-16">
                  총 모집인원 <span className="mr-5 font-bold">5명 중</span>
                  <span className="font-bold text-red">115명</span> 지원
                </p>
                <div className="absolute z-0 flex">
                  <img
                    className="h-40 w-52"
                    src={Soundbanner}
                    alt="Soundbanner"
                  ></img>
                  <img className="w-773 h-46 ml-11" src={Talk} alt="Talk"></img>
                </div>
                <p className="absolute font-bold right-25 text-24 top-10 text-red">
                  2300
                  <span className="ml-3 text-16">%</span>
                </p>

                <div className="flex items-center font-bold h-93 mt-86">
                  <div className="flex flex-col items-center justify-center text-white bg-white/10 w-194 h-93 rounded-28">
                    <p className="leading-27 text-18">1.리뷰어 선정 </p>
                    <p className="leading-24 text-16">02.05~02.20</p>
                  </div>
                  <img className="w-23 h-23" src={Caret} alt="Caret"></img>
                  <div className="flex flex-col items-center justify-center bg-logo w-194 h-93 rounded-28">
                    <p className="leading-27 text-18">2.리뷰어 선정 </p>
                    <p className="leading-24 text-16">02.21</p>
                  </div>
                  <img className="w-23 h-23" src={Caret} alt="Caret"></img>
                  <div className="flex flex-col items-center justify-center text-white bg-white/10 w-194 h-93 rounded-28">
                    <p className="leading-27 text-18">3.리뷰등록 </p>
                    <p className="leading-24 text-16">02.22~02.24</p>
                  </div>
                  <img className="w-23 h-23" src={Caret} alt="Caret"></img>
                  <div className="flex flex-col items-center justify-center text-white bg-white/10 w-194 h-93 rounded-28">
                    <p className="leading-27 text-18">4.베스트 발표 </p>
                    <p className="leading-24 text-16">02.26</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* section2 */}
        <div className="flex justify-center w-full bg-light-gray3 h-637 pt-97">
          <div className="flex max-w-1200 gap-61">
            <div className="flex flex-col gap-16">
              <img src={MovieDetail} className="h-438" alt="MovieDetail" />
              <div className="flex justify-center gap-14">
                <img
                  src={LeftArrow}
                  className="cursor-pointer"
                  alt="LeftArrow"
                />
                <p className="text-18">1 / 3</p>
                <img
                  src={RightArrow}
                  className="cursor-pointer"
                  alt="RightArrow"
                />
              </div>
            </div>

            <div className="flex flex-col gap-39">
              <div className="flex flex-col items-end gap-23">
                <p className="text-gray4 text-18">
                  참여 남은시간 ( 총 모집인원 5명 중 115명 지원 중)
                </p>
                <div className="flex items-center justify-center gap-10">
                  <img src={Clock} alt="Clock" />
                  <p className="font-bold text-red text-40">11시간 04분 09초</p>
                </div>
              </div>
              <div className="flex gap-16">
                <div className="flex items-center justify-center cursor-pointer gap-14 w-134 h-84 border-1 rounded-20 border-light-gray5">
                  <img src={Heart} alt="Heart" />
                  <p className="text-20 text-light-gray6">208</p>
                </div>
                <div className="flex items-center justify-center w-518 h-84 rounded-20 bg-logo border-1 border-orange4">
                  <img src={Mike} alt="Mike" />
                  <p className="font-bold cursor-pointer text-24 ml-7">
                    리뷰어 신청하기
                  </p>
                  <p className="ml-20 text-20">( 남은시간 11시간 04분 09초 )</p>
                </div>
              </div>
              <div className="bg-light-gray10 w-668 h-196 rounded-20 text-14 pt-38 pl-38 leading-24">
                <p>캠페인 안내</p>
                <p className="text-gray5">
                  제공된 가이드라인과 컨텐츠를 활용한 자유로운 리뷰 진행
                  <br /> * 리뷰어 선정 후 취소 요청 시 패널티가 자동으로
                  부여됩니다. (-5000P)
                  <br /> * 기간 내 리뷰 미등록 및 가이드라인 미준수 시 패널티가
                  자동으로 부여됩니다. (-5000P) <br />* 캠페인 신청 후 선정 시
                  취소가 불가능합니다. 이 점 숙지하시어 신중한 신청
                  부탁드립니다.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* section3 */}
        <div className="flex w-1200 pt-52">
          <div className="cursor-pointer border-l-1 border-r-1 border-t-1 text-15 py-18 px-55 border-light-gray7">
            캠페인 상세정보
          </div>

          <div className="flex gap-5 cursor-pointer border-b-1 text-15 py-18 px-55 border-light-gray7">
            신청자 한줄톡! <p className="text-light-gray11">(115/5)</p>
          </div>
          <div className="border-b-1 border-light-gray7 w-793"></div>
        </div>
        <div className="flex flex-col gap-40 ml-32 w-1200 mt-65 text-dark-gray2 mb-139">
          <div>
            <h1 className="font-bold text-18">👆🏻 제공 내역</h1>
            <p className="mt-15 text-14 leading-30">
              1. 영화 &lt;조용한 이주&gt; 인스타그램 가이드라인
              <br /> 2. 10,000PT(인스타그램)
              <br /> - 캠페인 종료 후 리워드 일괄 지급되며 일정외의 리뷰 연장은
              불가합니다. <br />- 블로그의 경우 글자수 1000자, 인스타그램의 경우
              5줄 이상의 내용을 필요로 합니다. <br />- 본 캠페인은 인스타그램
              팔로워 300명 이상 신청 가능합니다.
            </p>
          </div>

          <div>
            <h1 className="font-bold text-18">👆🏻 Emotion Icon해시태그</h1>
            <p className="mt-15 text-14 leading-30">
              필수 : #조용한이주 #영화조용한이주 #말레나최 #베를린국제영화제
              #전주국제영화제 #웰메이드무비 #3월20일개봉
              <br /> 추가 : #소셜라우더
              <br /> 인스타그램의 경우 태그는 피드본문에 작성
            </p>
          </div>

          <div>
            <h1 className="font-bold text-18">👆🏻 Emotion Icon캠페인미션</h1>
            <p className="mt-15 text-14 leading-30">
              1. 영화 개봉전 기대감을 (포스터,사진)을 담아 포스팅 작성
              <br /> ① 영화 예고편, 스틸컷, 정보 등을 활용하여 영화 정보 전달 및
              기대감 작성
              <br /> ② 인스타그램 피드 5줄 이상, 좋아요 수 공개 필수
              <br /> ​ 2. 포스팅 분위기(*참고용이며 복사/붙여넣기 지양합니다.)
              <br />- 해외 입양을 독특한 시선으로 담은 말레나 최 감독의
              #영화조용한이주
              <br /> - 한국계 덴마크 감독 &apos;말레나 최&apos;의 웰메이드무비!
              영화 &lt;조용한 이주&gt; 3월 20일 개봉
              <br /> - 제73회 #베를린국제영화제 국제영화비평가연맹상 수상,
              제24회 #전주국제영화제 초청 영화 #조용한이주
            </p>
          </div>

          <div className="text-14 leading-23 text-light-gray6">
            <p>
              Emotion Icon필수안내사항
              <br /> 1. 가이드라인 미 준수 시 수정 요청이 있을 수 있으며
              패널티가 부과됩니다.
              <br /> 2. 캠페인 포인트는 리뷰 마감일 후 검수를 통해 영업일 7일 내
              일괄 지급됩니다.
              <br /> 3. 업체 측 요청에 따라 선정 모집인원 및 리뷰 등록 일정 등
              변경될 수 있습니다.
              <br /> 4. 시사회 &amp; 체험존 &amp; 쇼케이스 캠페인의 경우 안내된
              일정에 맞춰 필수 참석하셔야 합니다.
              <br /> 5. 콘텐츠 유지 기간은 6개월입니다. 사전 고지없이 삭제 시
              포인트 반환 및 담당자 즉시 수정 요청이 있을 수 있습니다.
            </p>
          </div>
          <div className="text-14 leading-23 text-light-gray6">
            <p>
              Emotion Icon공정위 문구
              <br /> - 네이버 스마트에디터 ONE 그외 HTML 작성 불가능 에디터 전용
              대가성 라밸
              <br /> - 다음 라벨 이미지를 선택하고 복사하여, 블로그 포스팅
              에디터에 붙여넣기 해주세요. 사용방법) <br />
              이미지 왼쪽에서 오른쪽 드래그하여 복사해서 사용하시면 됩니다.
              <br />
              기존 HTML 작성 기능 에디터 전용 대가성 라벨 다음 코드를 복사하여,
              HTML 에디터 모드에서 붙여넣기 해주세요.
              <br /> (라벨 이미지 외 이미지에 기재되어있는 텍스트를 활용하여
              공정위 문구로 블로그 하단에 작성하셔도 괜찮습니다.)
            </p>
          </div>
          <img src={Notice} className="mt-10 w-450 h-71" alt="Notice" />
        </div>
      </div>

      {/* section4 */}

      <div className="flex flex-col w-full">
        <div className="flex justify-center bg-footer h-480">
          <div className="flex items-center justify-between w-1200">
            <div className="flex flex-col">
              <img src={SocialLouder} className="w-140" alt="SocialLouder" />
              <div className="flex text-white mt-43 ">
                <img src={Kakao} alt="Kakao" />
                <p className="text-16 ml-7">
                  쇼셜라우더 카카오톡 운영시간: 10:00~17:00 (주말 공휴일 휴무)
                </p>
              </div>
              <p className="mt-32 tracking-wider text-14 text-light-gray7 leading-23">
                서울특별시 강남구 도산대로59길 26, 2층(청담동, 히든힐하우스)
                <br />
                help@sociallouder.co.kr
              </p>
              <p className="mt-10 text-14 text-light-gray7 leading-23">
                사업자등록번호 : 658-88-02426 [사업자확인]
                <br /> 상호 : 주식회사 소셜라우더 | 대표자 : 최동인, 손나래
                <br />
                개인정보보호책임자 : 김하늘
              </p>
            </div>

            <div className="flex flex-col mt-105 text-15">
              <div className="flex items-center ml-40">
                <img src={Ad} alt="Ad" />
                <p className="text-white ml-7">쇼셜라우더 광고 문의</p>
                <div className="h-40 py-10 text-center cursor-pointer ml-86 border-1 border-black4 w-210 bg-black3 text-logo rounded-8">
                  플랫폼 제휴 문의하기
                </div>
              </div>

              <div className="flex items-center justify-center ml-37">
                <img src={Thumb} className=" mt-18" alt="Thumb" />
                <p className="ml-3 text-white mt-18 mr-28">
                  맞춤형 인플루언서 마케팅 문의
                </p>
                <div className="h-40 py-10 text-center cursor-pointer mt-22 border-1 border-black4 w-210 bg-black3 text-logo rounded-8">
                  맞춤 인플루언서 마케팅 문의
                </div>
              </div>

              <div className="flex items-center cursor-pointer border-y-1 border-light-gray8 py-14 mt-14 text-light-gray7">
                <p className="mr-60">
                  소셜라우더 BIZ 광고 보기 ㅣ 쇼설라우더 제안서 보기
                </p>
                <img src={Insta} className="w-27 mr-9" alt="Insta" />
                <img src={Facebook} className="w-27 mr-9" alt="Facebook" />
                <img src={BlogIcon} className="w-27" alt="BlogIcon" />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full bg-light-gray8 text-14 text-light-gray9">
          <div className="flex justify-between m-auto h-81 pt-30 w-1200">
            <p>
              Copyright © 2021 소셜라우더 - 영화SNS 블로그체험단 Inc. All rights
              reserved
            </p>
            <p className="cursor-pointer">
              회사소개 ㅣ 이용약관 ㅣ 개인정보처리방침
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
