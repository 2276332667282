import InstaExample1 from "assets/image/InstaExample1.png";
import InstaExample2 from "assets/image/InstaExample2.png";
import InstaExample3 from "assets/image/InstaExample3.png";
import InstaExample4 from "assets/image/InstaExample4.png";
import InstaExample5 from "assets/image/InstaExample5.png";
import InstaExample6 from "assets/image/InstaExample6.png";
import InstaExample7 from "assets/image/InstaExample7.png";
import InstaExample8 from "assets/image/InstaExample8.png";
import InstaExample9 from "assets/image/InstaExample9.png";
import ReferenceText from "assets/image/ReferenceText.png";
import { MultipleCarousel } from "components/common/MultipleCarousel";

export const ReferenceExample = () => {
  return (
    <div className="w-[46.87vw] h-[48.47vw]">
      <h4 className="font-bold ml-[4.06vw] mt-[4.29vw] tracking-tightest text-[1.48vw] text-very-dark-gray leading-[2.34vw]">
        퍼포먼스 마케팅은 이런 것 입니다. 검증된 성공 레퍼런스
      </h4>
      <div className="flex flex-col items-center w-full mt-[4.1vw]">
        <div className="h-[1.25vw] tracking-tight text-center w-[13.75vw] leading-[1.25vw] rounded-[0.62vw] bg-light-gray text-very-dark-gray">
          뚜렷한 영향력을 광고에 담습니다.
        </div>
        <img
          className="w-[26.32vw] h-[5.07vw] mt-[1.25vw]"
          src={ReferenceText}
          alt="textImg"
        ></img>
      </div>
      <div className="w-[46.87vw] mt-[2.03vw]">
        <MultipleCarousel
          images={CAROUSEL_IMAGES}
          amount={CAROUSEL_AMOUNT}
          size="w-[15.03vw] h-[23.43vw]"
        />
      </div>
      <hr className="max-w-1200 w-[46.87vw] border-light-gray border-1 mt-[2.03vw]"></hr>
    </div>
  );
};

const CAROUSEL_IMAGES = [
  InstaExample1,
  InstaExample2,
  InstaExample3,
  InstaExample4,
  InstaExample5,
  InstaExample6,
  InstaExample7,
  InstaExample8,
  InstaExample9,
];

const CAROUSEL_AMOUNT = 3;
