import Camera from "assets/image/Camera.png";
import Logo from "assets/image/Logo_lg.png";

export const Fixed = () => {
  return (
    <div className="w-screen h-[27.73vw] border-t-1 border-light-gray2">
      <div className="flex justify-center">
        <div>
          <p className="font-bold text-[1.71vw] text-very-dark-gray mt-[4.29vw]">
            #영화 #드라마 #예능 #OTT
            <br /> #디지털콘텐츠마케팅 은
          </p>
          <img
            className="w-[16.99vw] h-[7.53vw] mt-[1.91vw] mb-[1.91vw]"
            src={Logo}
            alt="Logo"
          ></img>
          <div className="w-[22.38vw] h-[3.94vw] bg-light-gray rounded-[0.46vw]">
            <p className="p-[0.78vw] text-[0.78vw] leading-[1.17vw] text-very-dark-gray">
              트렌드에 맞서 독보적인 콘텐츠로&nbsp;
              <span className="font-bold text-[0.85vw] text-logo">
                Creative한 퍼포먼스
              </span>
              를 선사하겠습니다!
            </p>
          </div>
        </div>
        <img
          className="w-[26.99vw] h-[23.47vw] mt-[2.26vw]"
          src={Camera}
          alt="Camera"
        ></img>
      </div>
    </div>
  );
};
