import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

interface IsMobileContextProps {
  isMobile: boolean;
  setIsMobile: Dispatch<SetStateAction<boolean>>;
}

const IsMobileContext = createContext<IsMobileContextProps>({
  isMobile: window.innerWidth <= 768,
  setIsMobile: () => {
    return window.innerWidth <= 768;
  },
});

export function IsMobileProvider({ children }: PropsWithChildren) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  return (
    <IsMobileContext.Provider value={{ isMobile, setIsMobile }}>
      {children}
    </IsMobileContext.Provider>
  );
}

export function useIsMobileContext() {
  return useContext(IsMobileContext);
}
