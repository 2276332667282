import InstagramBox1 from "assets/image/InstagramBox1.png";
import InstagramBox2 from "assets/image/InstagramBox2.png";
import InstagramBox3 from "assets/image/InstagramBox3.png";
import MarketingImg from "assets/image/MarketingImg.png";

export const MobileMarketing = () => {
  return (
    <div className="h-2090">
      <div className="flex flex-col items-center justify-center text-center">
        <h4 className="font-bold mt-82 text-22 text-very-dark-gray leading-32">
          콘텐츠 기획과 함께하는,<br></br> 인스타그램 마케팅
        </h4>
        <p className="mt-12 tracking-tight text-14 leading-24">
          인스타그램 채널 마케팅을 통해 이벤트, 콘텐츠
          <br /> 아이디어를 빠르고 파급력 있게 확산하여
          <br /> 팔로워를 고객으로 전환할 수 있습니다.
          <br /> 직관적인 분석과 높은 효율성을 경험할 수 있으며
          <br /> 경쟁업체 보다 발빠르고 트렌디하게 <br />
          표현할 수 있는 좋은 마케팅 수단입니다. <br />
          광고주 맞춤형 콘텐츠 기획으로 재미있고 다양한
          <br /> 아이디어로 최상의 파트너가 될 것이라 자부합니다.
          <br />
          소셜라우더에서는 전문적인 전략 수립과 크리에이티브한
          <br /> 콘텐츠 제작이 포함된 인스타그램 마케팅을 제시합니다.
        </p>
        <img
          className="mt-12 w-342 h-250 "
          src={MarketingImg}
          alt="MarketingImg"
        ></img>
        <hr className="mt-24 w-342 border-light-gray border-1"></hr>

        <div className="flex flex-col">
          <div className={DIV_STYLE}>
            <img
              className={IMG_STYLE}
              src={InstagramBox1}
              alt="InstagramBox1"
            ></img>
            <h5 className={H5_STYLE}>최적화 채널 선별</h5>
            <p className={DESC_STYLE}>
              작품, 브랜드와 적합한 타겟별 채널 선정 진행
            </p>
          </div>

          <div className={DIV_STYLE}>
            <img
              className={IMG_STYLE}
              src={InstagramBox2}
              alt="InstagramBox2"
            ></img>
            <h5 className={H5_STYLE}>파워 인플루언서 보유</h5>
            <p className={DESC_STYLE}>
              팔로워, 채널 활성도 등 프로파일 데이터로<br></br>최적의 인플루언서
              선정<br></br>
            </p>
          </div>

          <div className={DIV_STYLE}>
            <img
              className={IMG_STYLE}
              src={InstagramBox3}
              alt="InstagramBox3"
            ></img>
            <h5 className={H5_STYLE}>초고속 확산 마케팅</h5>
            <p className={`${DESC_STYLE} mb-15`}>
              알고리즘을 활용한 확산 마케팅 진행
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const DIV_STYLE = "flex flex-col items-center mt-35";
const H5_STYLE = "font-bold text-16 text-very-dark-gray mt-20";
const DESC_STYLE =
  "text-14 text-dark-gray leading-30 mt-10 tracking-tight text-center";
const IMG_STYLE = "w-250 h-300 drop-shadow-xl";
