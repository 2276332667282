import ReferenceImg1 from "assets/image/ReferenceImg1.png";
import ReferenceImg2 from "assets/image/ReferenceImg2.png";
import ReferenceImg3 from "assets/image/ReferenceImg3.png";

export const ReferenceChart = () => {
  return (
    <div className=" max-w-1200 w-[46.87vw] h-[26.05vw]">
      <h4 className="font-bold mt-[4.29vw] tracking-tightest text-[1.48vw] text-very-dark-gray leading-[2.34vw]">
        소셜라우더만의 검증된 성공 레퍼런스
      </h4>
      <div className="flex gap-[2.46vw] mt-[2.57vw]">
        {REFERENCE_LIST.map((item, index) => (
          <div
            key={`reference-list-${index}`}
            className="flex flex-col items-center gap-[1.68vw]"
          >
            <img src={item.image} alt="ReferenceImg"></img>
            <div className="h-[1.25vw] tracking-tight text-center min-w-[6.71vw] leading-[1.25vw] rounded-[0.62vw] bg-light-gray ">
              {item.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const REFERENCE_LIST = [
  { image: ReferenceImg1, title: "광고 의뢰 비율" },
  { image: ReferenceImg2, title: "광고 만족 요인" },
  { image: ReferenceImg3, title: "광고 만족도" },
];
