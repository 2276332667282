import SolutionImg1 from "assets/image/SolutionImg1.png";
import SolutionImg2 from "assets/image/SolutionImg2.png";
import SolutionImg3 from "assets/image/SolutionImg3.png";
import SolutionImg4 from "assets/image/SolutionImg4.png";
import SolutionImg5 from "assets/image/SolutionImg5.png";
import SolutionImg6 from "assets/image/SolutionImg6.png";
import SolutionImg7 from "assets/image/SolutionImg7.png";
import BothArrow from "assets/svg/BothArrow.svg";

export const MobileSolution = () => {
  return (
    <div className="flex flex-col items-center text-center h-[2547px] leading-30">
      <h4 className="font-bold mt-52 text-22 leading-32 text-very-dark-gray">
        데이터 기반으로 기획부터<br></br>섭외 및 실행까지 전략 맞춤 솔루션
      </h4>
      <p className="mt-32 text-orange">DMAX (Driving Max)</p>
      <img className="mt-24 w-342" src={BothArrow} alt="arrow"></img>
      <ul>
        <li className="flex flex-col items-center">
          <img className="mt-52" src={SolutionImg1} alt="img"></img>
          <p className="mt-18 text-18 text-orange">시장 분석</p>
          <p className="mt-12">
            업종, 타겟의 니즈, 인게이지먼트 등<br></br>고객 Buzz를 취합하여 광고
            방향성 설정
          </p>
        </li>
        <li className="flex flex-col items-center">
          <img className="mt-52" src={SolutionImg2} alt="img"></img>
          <p className="mt-18 text-18 text-orange">미디어 믹스</p>
          <p className="mt-12">
            분석된 데이터를 활용하여 타겟의<br></br>이동동선 별 최적화된
            IMC미디어 선정
          </p>
        </li>
        <li className="flex flex-col items-center">
          <img className="mt-52" src={SolutionImg3} alt="img"></img>
          <p className="mt-18 text-18 text-orange">콘텐츠 제작</p>
          <p className="mt-12">
            핵심 셀링포인트를 도출하고 타겟 접촉<br></br>미디어에 최적화된
            데이터 기반의<br></br>크리에이티브 소재 제작
          </p>
        </li>
        <li className="flex flex-col items-center">
          <img className="mt-52" src={SolutionImg4} alt="img"></img>
          <p className="mt-18 text-18 text-orange">퍼포먼스</p>
          <p className="mt-12">
            미디어에 최적화된 크리에이티브 소재를<br></br>활용한 A/B테스트, 구매
            타겟 최적화
          </p>
        </li>
        <li className="flex flex-col items-center">
          <img className="mt-52" src={SolutionImg5} alt="img"></img>
          <p className="mt-18 text-18 text-orange">성과 측정 및 분석</p>
          <p className="mt-12">
            광고로 유입된 고객의 제품 구매과정을<br></br>분석하고 인사이트
            도출을 통한 성과 극대화
          </p>
        </li>
      </ul>
      <p className="mt-52 text-orange">DMAX (Driving Max)</p>
      <img className="mt-24 w-342" src={BothArrow} alt="arrow"></img>
      <div className="flex flex-col items-center">
        <img className="mt-52" src={SolutionImg6} alt="img"></img>
        <p className="mt-18 text-18 text-orange">고객 최적화</p>
        <p className="mt-12">
          구매, 이탈, 고객에 대한 데이터를 수집하고<br></br>고객관리를 통한
          브랜드 신뢰도 및<br></br>충성 고객 확보
        </p>
      </div>
      <p className="mt-52 text-orange">DPORT (Data Report)</p>
      <img className="mt-24 w-342" src={BothArrow} alt="arrow"></img>
      <div className="flex flex-col items-center">
        <img className="mt-52" src={SolutionImg7} alt="img"></img>
        <p className="mt-18 text-18 text-orange">성과 관리</p>
        <p className="mt-12">
          내, 외부적 광고 집행 전반의 성과를<br></br>분석하고 생산성 개선 및
          관리
        </p>
      </div>
    </div>
  );
};
