import { MobileGap } from "components/common/MobileGap";
import { MobileMarketing } from "./MobileMarketing";
import { MobileReferenceChart } from "./MobileReferenceChart";
import { MobileReferenceExample } from "./MobileReferenceExample";
import { MobileInstagramAdventage } from "./MobileInstagramAdvantage";
import { MobileInfluencerExample } from "./MobileInfluencerExample";

export default function MobileInstagram() {
  return (
    <>
      <MobileMarketing />
      <MobileGap />
      <MobileReferenceChart />
      <MobileGap />
      <MobileReferenceExample />
      <MobileGap />
      <MobileInstagramAdventage />
      <MobileGap />
      <MobileInfluencerExample />
    </>
  );
}
