import solution from "assets/image/Solution.png";
import ArrowRight from "assets/svg/ArrowRight.svg";

export const MobileSolution = () => {
  return (
    <div className="h-580">
      <div className="flex flex-col items-center justify-center">
        <div className="text-center">
          <h4 className="font-bold text-22 text-very-dark-gray leading-32 mt-52">
            도달률 높은 유튜브 마케팅,<br></br> 지금 시작해보세요.
          </h4>
          <p className="mt-12 tracking-tighter text-14 leading-24">
            유튜브는 가장 대중적인 마케팅 채널입니다.<br></br> 임팩트 중심의
            쇼츠, 신뢰성 높은 리뷰 영상 등 유튜브<br></br> 마케팅으로 소중한
            문화 콘텐츠를 지금 홍보해보세요!
          </p>
        </div>
      </div>
      <img className="mt-40 w-342 h-225" src={solution} alt="solution" />
    </div>
  );
};
