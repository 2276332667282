import { useLocation } from "react-router-dom";

export const Footer = () => {
  const location = useLocation();
  const currentPage = location.pathname.split("/")[1];

  return (
    <div
      className={`flex items-center justify-center mt-30 bg-light-gray h-[7.81vw] mobile:h-130 text-very-dark-gray/70 ${
        currentPage === "homepage" && "hidden"
      }`}
    >
      <div className="max-w-1200 w-[46.87vw] text-[0.46] leading-[1.6] mobile:w-390 ml-20 mobile:text-11">
        <div className="flex flex-col">
          <p>
            주소 <span className="text-orange">_</span>
            <span className="text-black2">
              &nbsp;서울특별시 강남구 봉은사로37길
            </span>
            <p>
              상호 <span className="text-orange">_</span>
              <span className="text-black2">
                &nbsp;주식회사 소셜라우더 | 대표자 : 손나래, 최동인
              </span>
            </p>
          </p>
          <p>
            사업자등록번호 <span className="text-orange">_</span>
            <span className="text-black2">&nbsp;658-88-02426</span>
          </p>
          <p className=" text-black2">help@sociallouder.co.kr</p>
        </div>
      </div>
    </div>
  );
};
