import Simple from "assets/image/Simple.png";
import Marketing from "assets/image/Marketing.png";
import Message from "assets/image/Message.png";
import Mobile from "assets/image/Mobile.png";

export const MobileInstagramAdventage = () => {
  return (
    <div className="h-1050">
      <h4 className="font-bold text-center mt-52 tracking-tightest text-22 text-very-dark-gray leading-32">
        전국민이 사용하는 SNS 인스타그램<br></br>가장 핫한 마케팅 채널로서
        <br></br>
        최고의 효율을 자랑합니다.
      </h4>

      <div className="flex flex-col tracking-tighter">
        <div className={DIV_STYLE}>
          <div className={CONTENT_STYLE}>
            <img className="mt-10 w-80 h-80" src={Simple} alt="ThumsUp"></img>
            <h6 className="mt-10 font-bold text-22 text-very-dark-gray leading-40">
              누구나 간편하게 활용
            </h6>
            <p className="leading-20 text-dark-gray text-14">
              간편하게 배울 수 있는
              <br />
              효율적 플랫폼입니다.
            </p>
          </div>
        </div>

        <div className={DIV_STYLE}>
          <div className={CONTENT_STYLE}>
            <img
              className="mt-10 w-80 h-80"
              src={Marketing}
              alt="ThumsUp"
            ></img>
            <h6 className="mt-10 font-bold text-22 text-very-dark-gray leading-40">
              트렌드를 따르는 마케팅
            </h6>
            <p className=" text-dark-gray text-14 leading-20">
              트렌드에 맞는 이미지를 도달!
              <br />
              공감대를 형성합니다.
            </p>
          </div>
        </div>

        <div className={DIV_STYLE}>
          <div className={CONTENT_STYLE}>
            <img className="mt-10 w-80 h-80" src={Message} alt="ThumsUp"></img>
            <h6 className="mt-10 font-bold text-22 text-very-dark-gray leading-40">
              메세지를 전하는 플랫폼
            </h6>
            <p className=" text-dark-gray text-14 leading-20">
              스폰서 광고를 통한
              <br />
              맞춤 타켓팅 노출이 가능합니다.
            </p>
          </div>
        </div>

        <div className={DIV_STYLE}>
          <div className={CONTENT_STYLE}>
            <img className="mt-10 w-80 h-80" src={Mobile} alt="ThumsUp"></img>
            <h6 className="mt-10 font-bold text-22 text-very-dark-gray leading-40">
              모바일 시대에 딱!
            </h6>
            <p className=" text-dark-gray text-14 leading-20">
              페이스북의 뒤를 이은
              <br />
              대세 플랫폼입니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const DIV_STYLE =
  "mt-24 bg-white border-solid border-2 border-light-gray2 rounded-12 w-342 h-190";
const CONTENT_STYLE = "flex flex-col items-center justify-center text-center";
