import { MobileCustom } from "components/Products/MobileBlog/MobileCustom";
import { MobileMarketing } from "components/Products/MobileBlog/MobileMarketing";
import { MobileSolution } from "components/Products/MobileBlog/MobileSolution";
import { MobileGap } from "components/common/MobileGap";

export const MobileBlog = () => {
  return (
    <>
      <MobileMarketing />
      <MobileGap />
      <MobileSolution />
      <MobileGap />
      <MobileCustom />
    </>
  );
};
