import ReferenceImg1 from "assets/image/ReferenceImg1.png";
import ReferenceImg2 from "assets/image/ReferenceImg2.png";
import ReferenceImg3 from "assets/image/ReferenceImg3.png";

export const MobileReferenceChart = () => {
  return (
    <div className="h-1292">
      <h4 className="font-bold text-center mt-52 text-22 text-very-dark-gray leading-32">
        소셜라우더만의 검증된<br></br>성공 레퍼런스
      </h4>
      <div className="flex flex-col mt-24">
        {REFERENCE_LIST.map((item, index) => (
          <div
            key={`reference-list-${index}`}
            className="flex flex-col items-center gap-24"
          >
            <img
              className="w-339 h-304"
              src={item.image}
              alt="ReferenceImg"
            ></img>
            <div className="h-32 mb-24 tracking-tight text-center w-172 rounded-16 bg-light-gray leading-32">
              {item.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const REFERENCE_LIST = [
  { image: ReferenceImg1, title: "광고 의뢰 비율" },
  { image: ReferenceImg2, title: "광고 만족 요인" },
  { image: ReferenceImg3, title: "광고 만족도" },
];
