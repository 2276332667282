import MenuItem from "./MenuItem";

const menuItems = [
  { value: "blog", title: "블로그" },
  { value: "instagram", title: "인스타그램" },
  { value: "youtube", title: "유튜브" },
];

export const MobileNavbar = () => {
  return (
    <div>
      <ul className="flex">
        {menuItems.map((item) => (
          <li key={item.value}>
            <MenuItem {...item} />
          </li>
        ))}
      </ul>
    </div>
  );
};
