import { Header } from "components/Layout/Header";
import { Footer } from "components/Layout/Footer";
import { useIsMobileContext } from "context/IsMobileContext";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

export default function Root() {
  const { setIsMobile } = useIsMobileContext();

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", () => handleResize());
    return () => {
      window.removeEventListener("resize", () => handleResize());
    };
  });

  return (
    <div>
      <Header></Header>
      <Outlet></Outlet>
      <Footer></Footer>
    </div>
  );
}
