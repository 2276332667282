import MobileMockupImg from "assets/image/MobileMockup.png";

export const MobileMockup = () => {
  return (
    <div className="h-445">
      <p className="tracking-tight text-center text-orange text-14 leading-24 mt-52">
        광고주 전용 리포트 애드센터 제공
      </p>
      <h4 className="mt-12 font-bold text-center text-22 leading-32 text-very-dark-gray">
        인플루언서 모집부터<br></br>결과 보고서까지 원스톱
      </h4>
      <img className="ml-24 mt-52" src={MobileMockupImg} alt="mockup"></img>
    </div>
  );
};
