import Campaign1 from "assets/image/Campaign1.png";
import Campaign2 from "assets/image/Campaign2.png";
import Campaign3 from "assets/image/Campaign3.png";
import Campaign4 from "assets/image/Campaign4.png";
import Campaign5 from "assets/image/Campaign5.png";
import Campaign6 from "assets/image/Campaign6.png";
import { MobileMultipleCarousel } from "components/common/MobileMultipleCarousel";

export const MobileCampaign = () => {
  return (
    <div className="h-497">
      <h4 className="text-center mt-52 text-22 leading-32 text-very-dark-gray">
        지금 소셜라우더에서 진행되고 있는
      </h4>
      <h4 className="font-bold text-center text-22 leading-32 text-very-dark-gray">
        <span className="text-orange">다양한 분야</span>의<br></br>캠페인을
        만나보세요.
      </h4>
      <p className="mt-24 text-center leading-30">
        현재 <span className="text-orange">219,776+ </span>건의 캠페인이
        진행되고 있습니다.
      </p>
      <div className="mt-24 ml-24">
        <MobileMultipleCarousel images={CAROUSEL_IMAGES} />
      </div>
    </div>
  );
};

const CAROUSEL_IMAGES = [
  Campaign1,
  Campaign2,
  Campaign3,
  Campaign4,
  Campaign5,
  Campaign6,
];
