import Logo from "assets/image/Logo.png";
import Phone from "assets/svg/Phone.svg";
import { Link, useLocation } from "react-router-dom";

export const Header = () => {
  const location = useLocation();
  const currentPage = location.pathname.split("/")[1];

  return (
    <div
      className={`flex items-center justify-center bg-white h-[3.90vw] mobile:bg-background-gray mobile:h-52 ${
        currentPage === "homepage" && "hidden"
      }`}
    >
      <div
        className="flex justify-between text-black2 max-w-1200 w-[46.87vw] mobile:w-390 mobile:bg-white mobile:h-52
      "
      >
        <div className="flex items-center font-bold gap-[1.64vw] mobile:gap-0 mobile:h-52">
          <Link to="/">
            <img
              className="w-[5.46vw] h-[2.42vw] mobile:w-64 mobile:h-32 mobile:ml-24 cursor-pointer"
              src={Logo}
              alt="Logo"
            ></img>
          </Link>
          <Link
            className={`text-[0.62vw] ${
              !currentPage && "text-logo"
            } mobile:ml-55 mobile:text-14`}
            to="/"
          >
            COMPANY
          </Link>
          <Link
            className={`text-[0.62vw] ${
              currentPage === "products" && "text-logo"
            } mobile:ml-30 mobile:text-14`}
            to="/products"
          >
            PRODUCTS
          </Link>
        </div>
        <div className="flex items-center gap-[0.46vw] mobile:hidden">
          <img
            className="mb-2 w-[1.09vw] h-[1.09vw]"
            src={Phone}
            alt="Phone"
          ></img>
          <span className="text-[0.62vw] text-dark-gray">
            빠른 상담 : 02-515-2622
          </span>
        </div>
      </div>
    </div>
  );
};
