import { Banner } from "components/Company/Banner";
import { Campaign } from "components/Company/Campaign";
import { Chart } from "components/Company/Chart";
import { Contact } from "components/Company/Contact";
import { Influencer } from "components/Company/Influencer";
import { Mockup } from "components/Company/Mockup";
import { Service } from "components/Company/Service";
import { ServiceInfo } from "components/Company/ServiceInfo";
import { Slogan } from "components/Company/Slogan";
import { MobileBanner } from "components/MobileCompany/MobileBanner";
import { MobileCampaign } from "components/MobileCompany/MobileCampaign";
import { MobileChart } from "components/MobileCompany/MobileChart";
import { MobileInfluencer } from "components/MobileCompany/MobileInfluencer";
import { MobileMockup } from "components/MobileCompany/MobileMockup";
import { MobileService } from "components/MobileCompany/MobileService";
import { MobileServiceInfo } from "components/MobileCompany/MobileServiceInfo";
import { MobileSlogan } from "components/MobileCompany/MobileSlogan";
import { MobileContact } from "components/MobileCompany/MobileContact";
import { Gap } from "components/common/Gap";
import { MobileGap } from "components/common/MobileGap";
import { useIsMobileContext } from "context/IsMobileContext";

const Company = () => {
  const { isMobile } = useIsMobileContext();

  return (
    <>
      {isMobile ? (
        <div className="flex flex-col items-center w-full">
          <div className="w-390">
            <MobileSlogan />
            <MobileService />
            <MobileGap />
            <MobileMockup />
            <MobileGap />
            <MobileChart />
            <MobileGap />
            <MobileInfluencer />
            <MobileGap />
            <MobileServiceInfo />
            <MobileBanner />
            <MobileCampaign />
            <MobileGap />
            <MobileContact />
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <Slogan />
          <Service />
          <Gap />
          <Mockup />
          <Gap />
          <Chart />
          <Gap />
          <Influencer />
          <Gap />
          <ServiceInfo />
          <Banner />
          <Campaign />
          <Gap />
          <Contact />
        </div>
      )}
    </>
  );
};

export default Company;
