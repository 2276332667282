import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import Shorts from "assets/svg/Shorts.svg";

export const MobileExample = () => {
  return (
    <div>
      <h4 className="font-bold text-center mt-52 text-22 leading-32 text-very-dark-gray">
        소셜라우더는 크리에이티브와<br></br>퍼포먼스를 모두 갖춘<br></br>기획형
        바이럴 콘텐츠<br></br>솔루션을 제공합니다.
      </h4>
      <p className="mt-12 tracking-tight text-center text-orange text-14 leading-24">
        #기획 #제작 #분석 #관리 #광고 #SNS운영대행
      </p>
      <p className="mt-12 tracking-tight text-center text-14 leading-24">
        시청 VIEW 전환 퍼포먼스를 내기 위해서는 일반적인<br></br>영상만 만드는
        것이 아니라, SNS환경에 최적화 된<br></br>브랜드에 적합한 숏폼(쇼츠),
        맞춤형 콘텐츠를<br></br>만들어내야 합니다. 마케팅 전략 분석 및 콘텐츠에
        대한<br></br>이해도가 높은 소셜라우더와 함께 하세요. SNS숏폼<br></br>
        (쇼츠)의 다양한 퍼포먼스 영상을 제작합니다.
      </p>
      <div className="flex items-center mt-24 ml-24 gap-14">
        <img className="w-25 h-31" src={Shorts} alt="Shorts"></img>
        <span className="leading-30">Shorts</span>
      </div>
      <div className="flex flex-wrap gap-12 mt-24 ml-24 mb-52 w-342">
        {EXAMPLE_LIST.map((list) => (
          <div className="flex flex-wrap">
            <div className="overflow-hidden w-165 h-300 rounded-12">
              <iframe
                width="165"
                height="300"
                src={list.src}
                title={list.title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const EXAMPLE_LIST: { src: string; title: string }[] = [
  {
    src: "https://www.youtube.com/embed/9isQR9hmvHc",
    title: "인싸들이 쓴다는 모닝콜임 ㅋㅋㅋ #스즈메의문단속",
  },
  {
    src: "https://www.youtube.com/embed/lcZERad82wo",
    title:
      "다들 다이진의 브이로그 보고가!!#스즈메 #스즈메의문단속 #영화 #신카이마코토 #영화추천 #영화데이트 #영화명장면 #미디어캐슬 #다이진 #소타",
  },
  {
    src: "https://www.youtube.com/embed/fIRoUcaQmPI",
    title:
      "무슨 스파이더맨이 이렇게 많냐 ㄷㄷ #스파이더맨 #애스파 #스파이더맨:어크로스더유니버스 #소니 #소니픽쳐스 #마일스 #거미부대 #영화 #영화추천 #영화데이트 #개봉예정작",
  },
  {
    src: "https://www.youtube.com/embed/XF_IscfnkkE",
    title:
      "[극한직업] 이병헌 감독 신작 나왔다#드림 #영화 #박서준 #아이유 #이현우 #고창석 #이병헌감독 #영화추천 #영화데이트 #신작 #시사회 #개봉예정작",
  },
];
