import MobileCharacter from "assets/image/MobileCharacter.png";

export const MobileService = () => {
  return (
    <div className="h-615">
      <p className="tracking-tight text-center text-orange text-14 leading-24 mt-52">
        블로그부터 인스타그램 릴스, 유튜브(쇼츠),<br></br>커뮤니티 바이럴까지
        소셜라우더의 경쟁력을 확인하세요.
      </p>
      <h4 className="mt-12 font-bold text-center text-22 leading-32 text-very-dark-gray">
        소셜라우더만의 센스있는<br></br>마케팅 기획과 함께
      </h4>
      <p className="mt-12 tracking-tight text-center text-14 leading-24">
        소셜라우더는 광고주의 상황과 예산에 맞는 최적의 마케팅<br></br>구성을
        제안드립니다.<br></br>최소한의 비용으로 최고의 효율을 낼 수 있도록
        검증된<br></br>Database로 최적화된 서비스를 제공해드립니다.
      </p>
      <img className="mt-52" src={MobileCharacter} alt="character"></img>
    </div>
  );
};
