import CaretLeft from "assets/svg/CaretLeft.svg";
import CaretRight from "assets/svg/CaretRight.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

interface Props {
  images: string[];
  amount: number;
  size: string;
}

export const MultipleCarousel = ({ images, amount, size }: Props) => {
  const CustomNextArrow = (props: {
    className: string;
    onClick: () => void;
  }) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img className={className} src={CaretRight} alt="Right" />
      </div>
    );
  };

  const CustomPrevArrow = (props: {
    className: string;
    onClick: () => void;
  }) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img className={className} src={CaretLeft} alt="Left" />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: amount,
    slidesToScroll: amount,
    nextArrow: <CustomNextArrow className={""} onClick={() => onclick} />,
    prevArrow: <CustomPrevArrow className={""} onClick={() => onclick} />,
    variableWidth: false,
  };

  return (
    <Slider {...settings}>
      {images.map((img, index) => (
        <div key={`card-img-${index}`} className="relative ">
          <img
            className={`${size} rounded-12 `}
            src={img}
            alt={`carouselImg-${index}`}
          />
        </div>
      ))}
    </Slider>
  );
};
