import { Gap } from "components/common/Gap";
import { InfluencerExample } from "components/Products/Instagram/InfluencerExample";
import { InstagramAdventage } from "components/Products/Instagram/InstagramAdventage";
import { Marketing } from "components/Products/Instagram/Marketing";
import { ReferenceChart } from "components/Products/Instagram/ReferenceChart";
import { ReferenceExample } from "components/Products/Instagram/ReferenceExample";

export const Instagram = () => {
  return (
    <>
      <Marketing />
      <Gap />
      <ReferenceChart />
      <Gap />
      <ReferenceExample />
      <Gap />
      <InstagramAdventage />
      <Gap />
      <InfluencerExample />
    </>
  );
};
