import { Gap } from "components/common/Gap";
import { MobileGap } from "components/common/MobileGap";
import { Blog } from "components/Products/Blog/Blog";
import { Fixed } from "components/Products/Fixed";
import { MobileFixed } from "components/Products/MobileFixed";
import { Instagram } from "components/Products/Instagram/Instagram";
import MobileInstagram from "components/Products/MobileInstagram/MobileInstagram";
import { MobileBlog } from "components/Products/MobileBlog/MobileBlog";
import { MobileNavbar } from "components/Products/MobileNavbar";
import { Navbar } from "components/Products/Navbar";
import { Youtube } from "components/Products/Youtube/Youtube";
import { MobileYoutube } from "components/Products/MobileYoutube/MobileYoutube";
import { useIsMobileContext } from "context/IsMobileContext";
import { NavbarProvider, useNavbar } from "context/NavbarContext";

const Products = () => {
  const { isMobile } = useIsMobileContext();

  return (
    <>
      <NavbarProvider>
        {isMobile ? <MobileFixed /> : <Fixed />}
        {isMobile ? <MobileGap /> : <Gap />}
        <Content />
      </NavbarProvider>
    </>
  );
};

const Content = () => {
  const { activeMenu } = useNavbar();
  const { isMobile } = useIsMobileContext();

  return (
    <div className="flex flex-col items-center">
      {isMobile ? <MobileNavbar /> : <Navbar />}
      {activeMenu === "instagram" &&
        (isMobile ? <MobileInstagram /> : <Instagram />)}
      {activeMenu === "blog" && (isMobile ? <MobileBlog /> : <Blog />)}
      {activeMenu === "youtube" && (isMobile ? <MobileYoutube /> : <Youtube />)}
    </div>
  );
};

export default Products;
