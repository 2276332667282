import CardImg1 from "assets/image/CardImg1.png";
import CardImg2 from "assets/image/CardImg2.png";
import CardImg3 from "assets/image/CardImg3.png";
import CardImg4 from "assets/image/CardImg4.png";
import CardImg5 from "assets/image/CardImg5.png";
import { MobileCarousel } from "components/common/MobileCarousel";

export const MobileChart = () => {
  return (
    <div className="w-390 h-290 mt-52">
      <MobileCarousel images={CAROUSEL_IMAGES} className="w-300 h-240 ml-45" />
    </div>
  );
};

const CAROUSEL_IMAGES = [CardImg1, CardImg2, CardImg3, CardImg4, CardImg5];
