import Simple from "assets/image/Simple.png";
import Marketing from "assets/image/Marketing.png";
import Message from "assets/image/Message.png";
import Mobile from "assets/image/Mobile.png";

export const InstagramAdventage = () => {
  return (
    <div className="max-w-1200 h-[25.70vw]">
      <h4 className="font-bold mt-[4.29vw] tracking-tightest text-[1.48vw] text-very-dark-gray leading-[2.34vw]">
        전국민이 사용하는 SNS 인스타그램<br></br>가장 핫한 마케팅 채널로서
        최고의 효율을 자랑합니다.
      </h4>

      <div className="flex w-[46.87vw] gap-[0.93vw] tracking-tight mb-[2.03vw]">
        <div className="bg-white border-solid border-2 border-light-gray2 rounded-[0.46vw] w-[15vw] h-[9.29vw] mt-[1.44vw]">
          <div className="flex flex-col items-center justify-center text-center">
            <img
              className="w-[3.12vw] h-[3.12vw] mt-[0.78vw]"
              src={Simple}
              alt="Simple"
            ></img>
            <h6 className="font-bold text-[0.93vw] text-very-dark-gray leading-[1.56vw] mt-[0.58vw]">
              누구나 간편하게 활용
            </h6>
            <p className=" text-dark-gray text-[0.62vw] leading-[1.17vw] tracking-tight">
              간편하게 배울 수 있는
              <br />
              효율적 플랫폼입니다.
            </p>
          </div>
        </div>

        <div className="bg-white border-solid border-2 border-light-gray2 rounded-[0.46vw] w-[15vw] h-[9.29vw] mt-[1.44vw]">
          <div className="flex flex-col items-center justify-center text-center">
            <img
              className="w-[3.12vw] h-[3.12vw] mt-[0.78vw]"
              src={Marketing}
              alt="Marketing"
            ></img>
            <h6 className="font-bold text-[0.93vw] text-very-dark-gray leading-[1.56vw] mt-[0.58vw]">
              트렌드를 따르는 마케팅
            </h6>
            <p className=" text-dark-gray text-[0.62vw] leading-[1.17vw] tracking-tight">
              트렌드에 맞는 이미지를 도달!
              <br />
              공감대를 형성합니다.
            </p>
          </div>
        </div>

        <div className="bg-white border-solid border-2 border-light-gray2 rounded-[0.46vw] w-[15vw] h-[9.29vw] mt-[1.44vw]">
          <div className="flex flex-col items-center justify-center text-center">
            <img
              className="w-[3.12vw] h-[3.12vw] mt-[0.78vw]"
              src={Message}
              alt="Message"
            ></img>
            <h6 className="font-bold text-[0.93vw] text-very-dark-gray leading-[1.56vw] mt-[0.58vw]">
              메세지를 전하는 플랫폼
            </h6>
            <p className=" text-dark-gray text-[0.62vw] leading-[1.17vw] tracking-tight">
              스폰서 광고를 통한
              <br />
              맞춤 타겟팅 노출이 가능합니다.
            </p>
          </div>
        </div>

        <div className="bg-white border-solid border-2 border-light-gray2 rounded-[0.46vw] w-[15vw] h-[9.29vw] mt-[1.44vw]">
          <div className="flex flex-col items-center justify-center text-center">
            <img
              className="w-[3.12vw] h-[3.12vw] mt-[0.78vw]"
              src={Mobile}
              alt="Mobile"
            ></img>
            <h6 className="font-bold text-[0.93vw] text-very-dark-gray leading-[1.56vw] mt-[0.58vw]">
              모바일 시대에 딱!
            </h6>
            <p className=" text-dark-gray text-[0.62vw] leading-[1.17vw] tracking-tight">
              페이스북의 뒤를 이은
              <br />
              대세 플랫폼입니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
