import InstagramBox1 from "assets/image/InstagramBox1.png";
import InstagramBox2 from "assets/image/InstagramBox2.png";
import InstagramBox3 from "assets/image/InstagramBox3.png";
import MarketingImg from "assets/image/MarketingImg.png";

export const Marketing = () => {
  return (
    <div className="max-w-1200 w-[46.87vw] mb-[2.03vw]">
      <div className="relative">
        <h4 className="font-bold mt-[7.1vw] tracking-tightest text-[1.48vw] text-very-dark-gray leading-[2.34vw]">
          콘텐츠 기획과 함께하는, 인스타그램 마케팅
        </h4>
        <p className="mt-[0.31vw] tracking-tight leading-[1.17vw] w-[23.43vw] text-justify">
          인스타그램 채널 마케팅을 통해 이벤트, 콘텐츠 아이디어를 빠르고 파급력
          있게 확산하여 팔로워를 고객으로 전환할 수 있습니다. 직관적인 분석과
          높은 효율성을 경험할 수 있으며 경쟁업체 보다 발빠르고 트렌디하게
          표현할 수 있는 좋은 마케팅 수단입니다. 광고주 맞춤형 콘텐츠 기획으로
          재미있고 다양한 아이디어로 최상의 파트너가 될 것이라 자부합니다.
          소셜라우더에서는 전문적인 전략 수립과 크리에이티브한 콘텐츠 제작이
          포함된 인스타그램 마케팅을 제시합니다.
        </p>
        <img
          className="absolute w-[30.22vw] h-[19.09vw] -right-[4.10vw] -top-[5.9vw]"
          src={MarketingImg}
          alt="MarketingImg"
        ></img>
        <hr className="w-full border-light-gray border-1 mt-[4.88vw]"></hr>

        <div className="flex justify-center gap-[2.34vw]">
          <div className={DIV_STYLE}>
            <img
              className={IMG_STYLE}
              src={InstagramBox1}
              alt="InstagramBox1"
            ></img>
            <h5 className={H5_STYLE}>최적화 채널 선별</h5>
            <p className={DESC_STYLE}>
              작품, 브랜드와 적합한 타겟별<br></br>채널 선정 진행<br></br>
            </p>
          </div>

          <div className={DIV_STYLE}>
            <img
              className={IMG_STYLE}
              src={InstagramBox2}
              alt="InstagramBox2"
            ></img>
            <h5 className={H5_STYLE}>파워 인플루언서 보유</h5>
            <p className={DESC_STYLE}>
              팔로워, 채널 활성도 등 프로파일 데이터로 최적의 인플루언서 선정
            </p>
          </div>

          <div className={DIV_STYLE}>
            <img
              className={IMG_STYLE}
              src={InstagramBox3}
              alt="InstagramBox3"
            ></img>
            <h5 className={H5_STYLE}>초고속 확산 마케팅</h5>
            <p className={DESC_STYLE}>
              알고리즘을 활용한<br></br>확산 마케팅 진행<br></br>
            </p>
          </div>
        </div>
        <hr className="w-full border-light-gray border-1 mt-[1.5vw]"></hr>
      </div>
    </div>
  );
};

const DIV_STYLE = "flex flex-col items-center mt-[1.5vw]";
const H5_STYLE = "font-bold text-[0.93vw] text-very-dark-gray mt-[2vw]";
const DESC_STYLE =
  "text-dark-gray leading-[1.17vw] mt-[0.5vw] tracking-tight text-center break-keep w-[11.71vw]";
const IMG_STYLE =
  "w-[11.76vw] h-[17.93vw] rounded-12 drop-shadow-xl mt-[0.39vw]";
