import emailjs from "@emailjs/browser";
import ContactIcon1 from "assets/image/ContactIcon1.png";
import ContactIcon2 from "assets/image/ContactIcon2.png";
import ContactIcon3 from "assets/image/ContactIcon3.png";

import { useRef } from "react";

export const MobileContact = () => {
  const form = useRef<HTMLFormElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (emailRef.current && validateEmail(emailRef.current.value)) {
      form.current &&
        emailjs.sendForm(
          "service_91awnk8", //"YOUR_SERVICE_ID",
          "template_a6q9zgs", //"YOUR_TEMPLATE_ID",
          form.current,
          "Lta3YKuSkE-I5pGPO" //"YOUR_PUBLIC_KEY"
        );
      alert("성공적으로 메일을 송신하였습니다.");
    } else {
      alert("올바른 이메일을 입력해주세요.");
    }
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center gap-24">
        <div className="w-342">
          <p className="mb-40 font-bold text-22">
            소셜라우더 BIZ 마케팅 문의하기
          </p>
          <form ref={form} onSubmit={(e) => sendEmail(e)}>
            <label className="block font-bold text-14">
              회신 받을 메일 주소 (필수 사항)
            </label>
            <input
              ref={emailRef}
              className={INPUT_STYLE}
              type="email"
              name="email"
              placeholder="회신받으실 메일 주소를 적어주세요."
            />
            <label className="block font-bold text-14">문의 제목</label>
            <input
              className={INPUT_STYLE}
              type="text"
              name="title"
              placeholder="문의하실 내용의 제목을 적어주세요."
            />
            <label className="block font-bold text-14">문의 내용</label>
            <textarea
              className="flex items-center w-full p-10 mt-10 mb-24 bg-transparent outline-none resize-none text-12 border-1 border-gray2 h-76 placeholder:text-12 placeholder:text-dark-gray"
              name="detail"
              placeholder="문의하실 내용을 적어주세요. *"
            />
            <div className="flex justify-center w-full">
              <input
                type="submit"
                value="무료 상담하기"
                className="flex items-center justify-center w-full h-40 font-bold text-white bg-black cursor-pointer rounded-10 text-12"
              ></input>
            </div>
          </form>
        </div>
        <div className="flex flex-col mt-40 text-white bg-black w-390 p-18 leading-30 h-200 m-30">
          <div className="flex justify-between w-190">
            <div className="flex flex-col gap-10">
              <div className="flex gap-10">
                <img
                  className="w-20 h-20 mt-3"
                  src={ContactIcon1}
                  alt="ContactIcon1"
                ></img>
                <div className="w-170">
                  <span className="font-bold">전화문의</span>
                  <span className="text-12">
                    <br></br>(월~금, 10시~17시)<br></br>02-515-2622
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-end gap-10 mt-10">
              <img
                className="mb-4 w-25 h-25"
                src={ContactIcon2}
                alt="ContactIcon2"
              ></img>
              <a
                href="https://pf.kakao.com/_xbATSb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="font-bold cursor-pointer text-18 w-150">
                  @소셜라우더
                </p>
              </a>
            </div>
          </div>
          <div className="flex gap-10 mt-10 ">
            <img
              className="w-20 h-20 mt-3"
              src={ContactIcon3}
              alt="ContactIcon3"
            ></img>
            <p>
              <a
                href="https://place.map.kakao.com/536696208"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="font-bold cursor-pointer">찾아오시는 길</span>
              </a>
              <span className="text-12">
                <br></br>서울 강남구 도산대로59길 26 2층
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

// const SOCIAL_LOUDER_EMAIL = "help@sociallouder.co.kr";
// const SOCIAL_LOUDER_PASSWORD = "ttfde1234!!";
const INPUT_STYLE =
  "flex items-center w-full mt-10 mb-12 bg-transparent border-b-2 outline-none h-28 text-12 placeholder:text-dark-gray pl-10 placeholder:text-12";
