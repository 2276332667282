import CardImg1 from "assets/image/CardImg1.png";
import CardImg2 from "assets/image/CardImg2.png";
import CardImg3 from "assets/image/CardImg3.png";
import CardImg4 from "assets/image/CardImg4.png";
import CardImg5 from "assets/image/CardImg5.png";
import { Carousel } from "components/common/Carousel";

export const Chart = () => {
  return (
    <div className="w-[46.87vw] h-[43.82vw] mt-[4.29vw]">
      <Carousel images={CAROUSEL_IMAGES} />
    </div>
  );
};

const CAROUSEL_IMAGES = [CardImg1, CardImg2, CardImg3, CardImg4, CardImg5];
