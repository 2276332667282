import Slider from "react-slick";

interface Props {
  images: string[];
  className: string;
}

export const MobileCarousel = ({ images, className }: Props) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    swipeToSlide: true,
    variableWidth: false,
  };

  return (
    <Slider {...settings}>
      {images.map((img, index) => (
        <div key={`card-img-${index}`} className="relative">
          <img className={className} src={img} alt={`carouselImg-${index}`} />
        </div>
      ))}
    </Slider>
  );
};
