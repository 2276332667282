import emailjs from "@emailjs/browser";
import ContactIcon1 from "assets/image/ContactIcon1.png";
import ContactIcon2 from "assets/image/ContactIcon2.png";
import ContactIcon3 from "assets/image/ContactIcon3.png";

import { useRef } from "react";

export const Contact = () => {
  const form = useRef<HTMLFormElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const titleRef = useRef<HTMLInputElement>(null);
  const contentRef = useRef<HTMLTextAreaElement>(null);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!emailRef.current?.value) {
      alert("이메일을 입력해주세요.");
    } else if (!validateEmail(emailRef.current.value)) {
      alert("올바른 이메일을 입력해주세요.");
    } else if (!titleRef.current?.value) {
      alert("문의 제목을 입력해주세요.");
    } else if (!contentRef.current?.value) {
      alert("문의 내용을 입력해주세요.");
    } else {
      form.current &&
        emailjs.sendForm(
          "service_8h7vtu8",
          "template_y3x5don",
          form.current,
          "ahd-iasKL96YIwpoO"
        );
      alert("성공적으로 메일을 송신하였습니다.");
    }
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  return (
    <div>
      <div className="flex gap-[2.42vw] w-[46.87vw] justify-center">
        <div className="w-[31.25vw] mt-[4.29vw]">
          <p className="flex w-full font-bold text-[1.48vw] mb-[3.28vw]">
            소셜라우더 BIZ 마케팅 문의하기
          </p>
          <form ref={form} onSubmit={(e) => sendEmail(e)}>
            <label className="block font-bold text-[0.78vw]">
              회신 받을 메일 주소
            </label>
            <input
              ref={emailRef}
              className={INPUT_STYLE}
              type="email"
              name="email"
              placeholder="회신받으실 메일 주소를 적어주세요. *"
            />
            <label className="block font-bold text-[0.78vw]">문의 제목</label>
            <input
              ref={titleRef}
              className={INPUT_STYLE}
              type="text"
              name="title"
              placeholder="문의하실 내용의 제목을 적어주세요. *"
            />
            <label className="block font-bold text-[0.78vw]">문의 내용</label>
            <textarea
              ref={contentRef}
              className="flex items-center w-full p-[0.46vw] mt-[0.46vw] mb-[0.93vw] bg-transparent outline-none resize-none border-1 border-gray2 h-[5.93vw] placeholder:text-[0.70vw] placeholder:text-dark-gray"
              name="detail"
              placeholder="문의하실 내용을 적어주세요. *"
            />
            <div className="flex justify-center w-full">
              <input
                type="submit"
                value="무료 상담하기"
                className="flex items-center justify-center font-bold text-white bg-black cursor-pointer mt-[2.03vw] mb-[4.29vw] w-full h-[2.65vw] rounded-[0.46vw]"
              ></input>
            </div>
          </form>
        </div>
        <div className="flex flex-col text-white bg-black p-[2.03vw] pr-[1.2vw] leading-[1.17vw] mt-[9.06vw] w-[15.62vw] h-[15.62vw]">
          <div className="flex gap-[0.46vw]">
            <img
              className="w-[1.25vw] h-[1.25vw] -mt-[0.07vw]"
              src={ContactIcon1}
              alt="ContactIcon1"
            ></img>
            <p>
              <span className="font-bold text-[1.09vw]">전화문의</span>
              <br></br>(월~금, 10시~17시)<br></br>02-515-2622
            </p>
          </div>
          <div className="flex gap-[0.46vw] mt-[1.56vw]">
            <img
              className="w-[1.25vw] h-[1.25vw] -mt-[0.07vw]"
              src={ContactIcon2}
              alt="ContactIcon2"
            ></img>
            <a
              href="https://pf.kakao.com/_xbATSb"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="font-bold text-[1.09vw] cursor-pointer">
                @소셜라우더
              </p>
            </a>
          </div>
          <div className="flex gap-[0.46vw] mt-[0.78vw]">
            <img
              className="w-[1.25vw] h-[1.25vw] -mt-[0.07vw]"
              src={ContactIcon3}
              alt="ContactIcon3"
            ></img>
            <p>
              <a
                href="https://place.map.kakao.com/536696208"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="font-bold text-[1.09vw] cursor-pointer">
                  찾아오시는 길
                </span>
              </a>
              <br />
              <span className="-ml-[0.52vw]">
                서울 강남구 도산대로59길 26 2층
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const INPUT_STYLE =
  "flex items-center w-full mt-[0.46vw] mb-[0.93vw] bg-transparent border-b-2 outline-none h-[2.03vw] placeholder:text-[0.7vw] placeholder:text-dark-gray pl-[0.46vw]";
