import MenuItem from "./MenuItem";

const menuItems = [
  { value: "blog", title: "블로그 퍼포먼스" },
  { value: "instagram", title: "인스타그램 퍼포먼스" },
  { value: "youtube", title: "유튜브 퍼포먼스" },
];

export const Navbar = () => {
  return (
    <div>
      <ul className="flex">
        {menuItems.map((item) => (
          <MenuItem key={item.value} {...item} />
        ))}
      </ul>
    </div>
  );
};
