import Instagram from "assets/image/Instagram.png";
import Naver from "assets/image/Naver.png";
import Youtube from "assets/image/Youtube.png";

export const MobileBanner = () => {
  return (
    <div className="flex flex-col items-center pt-24 text-center bg-black h-1042">
      <p className="tracking-tight text-orange text-14 leading-24">
        소셜라우더 BIZ 에서는
      </p>
      <h4 className="mt-12 font-bold text-white text-22 leading-32">
        어떤 채널의 인플루언서도<br></br>문제없이 매칭해드립니다.
      </h4>
      <img className="mt-32" src={Instagram} alt="Instagram"></img>
      <p className="font-bold tracking-tighter text-white mt-18 text-24 leading-40">
        INSTAGRAM
      </p>
      <p className="text-light-gray2 leading-30 mt-18">
        브랜드 핏에 꼭 맞는 인스타그램 인플루언서를<br></br>섭외하여 이미지,
        영상, 릴스, 스토리 등<br></br>인스타그램의 모든 콘텐츠 형식으로 캠페인
        진행
      </p>
      <img className="mt-32" src={Naver} alt="Naver"></img>
      <p className="font-bold tracking-tighter text-white mt-18 text-24 leading-40">
        NAVER INFLUENCER
      </p>
      <p className="text-light-gray2 leading-30 mt-18">
        네이버에서 선정한 분야별 전문 인플루언서로만<br></br>진행되는 캠페인으로
        약 9,500명의<br></br>인플루언서를 선택하여 진행
      </p>
      <img className="mt-32" src={Youtube} alt="Youtube"></img>
      <p className="font-bold tracking-tighter text-white mt-18 text-24 leading-40">
        YOUTUBE
      </p>
      <p className="text-light-gray2 leading-30 mt-18">
        브랜드 핏에 맞는 유튜버 or 구독자 1만명 이상의<br></br>유튜버가
        보장되고, 캠페인 미션에 따라<br></br>브랜디드, PPL타입 제작 가능
      </p>
    </div>
  );
};
