import { useNavbar } from "context/NavbarContext";
import { useIsMobileContext } from "context/IsMobileContext";

const NAVBAR_STYLE =
  "w-[15.62vw] h-[2.65vw] cursor-pointer text-center text-[0.78vw] py-[0.74vw] leading-[1.17vw]";
const ACTIVE_STYLE = "border-b-4 border-logo font-bold";
const MOBILE_NAVBAR_STYLE =
  " mt-15 mr-12 cursor-pointer w-115 h-48 rounded-26 font-bold text-16 text-center shadow-menu leading-48 text-very-dark-gray";
const MOBILE_ACTIVE_STYLE = "bg-logo text-white";

interface MenuItemProps {
  value: string;
  title: string;
}

const MenuItem = ({ value, title }: MenuItemProps) => {
  const { activeMenu, setActiveMenu } = useNavbar();
  const { isMobile } = useIsMobileContext();

  return (
    <li
      className={`${isMobile ? MOBILE_NAVBAR_STYLE : NAVBAR_STYLE} ${
        activeMenu === value
          ? isMobile
            ? MOBILE_ACTIVE_STYLE
            : ACTIVE_STYLE
          : ""
      }`}
      onClick={() => setActiveMenu(value)}
    >
      {title}
    </li>
  );
};

export default MenuItem;
