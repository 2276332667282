import GrayArrow from "assets/svg/GrayArrow.svg";

export const Process = () => {
  return (
    <div className="max-w-1200 w-[46.87vw] h-[17.73vw]">
      <h4 className="font-bold text-[1.48vw] text-very-dark-gray leading-[2.34vw] mt-[4.29vw] ">
        숏폼 콘텐츠 제작 - 진행절차
      </h4>
      <div className="flex justify-between">
        <div className="w-[7.26vw]">
          <h5 className={H5_STYLE}>01.</h5>
          <p className={DESC1_STYLE}>구독 신청 및 계약서 작성</p>
          <p className={DESC2_STYLE}>구독 상품 결정 및 계약</p>
        </div>
        <img
          className="w-[0.54vw] mt-[2.73vw]"
          src={GrayArrow}
          alt="GrayArrow"
        />
        <div className="w-[7.26vw]">
          <h5 className={H5_STYLE}>02.</h5>
          <p className={DESC1_STYLE}>월간 기획 리포트 전달</p>
          <p className={DESC2_STYLE}>
            월간 이슈/트렌드에 맞추어 콘텐츠 제작 방향에 관한 기획 리포트 전달
          </p>
        </div>
        <img
          className="w-[0.54vw] mt-[2.73vw]"
          src={GrayArrow}
          alt="GrayArrow"
        />
        <div className="w-[7.26vw]">
          <h5 className={H5_STYLE}>03.</h5>
          <p className={DESC1_STYLE}>제품 정보 및 제품 전달</p>
          <p className={DESC2_STYLE}>
            제품에 맞는 영상 제작을 위한 제품 정보 및 촬영용 제품 전달
          </p>
        </div>
        <img
          className="w-[0.54vw] mt-[2.73vw]"
          src={GrayArrow}
          alt="GrayArrow"
        />
        <div className="w-[7.26vw]">
          <h5 className={H5_STYLE}>04.</h5>
          <p className={DESC1_STYLE}>콘텐츠 제작</p>
          <p className={DESC2_STYLE}>구독 수량에 맞춰 영상 제작</p>
        </div>
        <img
          className="w-[0.54vw] mt-[2.73vw]"
          src={GrayArrow}
          alt="GrayArrow"
        />
        <div className="w-[7.26vw]">
          <h5 className={H5_STYLE}>05.</h5>
          <p className={DESC1_STYLE}>콘텐츠 및 키워드 리포트 제공</p>
          <p className={DESC2_STYLE}>
            콘텐츠 납품 및 콘텐츠 활용 시 사용할 수 있는 키워드 리포트 제공
          </p>
        </div>
      </div>
    </div>
  );
};

const H5_STYLE = "font-bold text-orange text-[1.25vw] mt-[2.03vw] -ml-[0.39vw]";
const DESC1_STYLE = "font-bold mt-[0.70vw] text-center";
const DESC2_STYLE = "mt-[0.46vw] tracking-tighter leading-[1.17vw] text-center";
