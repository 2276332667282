import Logo from "assets/image/BLogo.png";
import Bubble from "assets/image/Bubble.png";
import BArrow from "assets/svg/BackArrow.svg";
import TArrow from "assets/svg/TopArrow.svg";
import Search from "assets/svg/Search.svg";
import Lock from "assets/svg/Lock.svg";
import Sign from "assets/svg/Sign.svg";
import Banner from "assets/image/Banner.png";
import Movie from "assets/image/Movie.png";
import Series from "assets/image/Series.png";
import Entertainment from "assets/image/Entertainment.png";
import Show from "assets/image/Show.png";
import Book from "assets/image/Book.png";
import BannerLogo from "assets/image/BannerLogo.png";
import BigBannerLogo from "assets/image/BigBannerLogo.png";
import Blog from "assets/image/Blog.png";
import Insta from "assets/svg/Insta.svg";
import Youtube from "assets/svg/Youtube.svg";
import Campaign from "assets/svg/Campaign.svg";
import Review from "assets/svg/Review.svg";
import Question from "assets/svg/Question.svg";
import Movie1 from "assets/image/Movie1.png";
import Movie2 from "assets/image/Movie2.png";
import Movie3 from "assets/image/Movie3.png";
import Movie4 from "assets/image/Movie4.png";
import Movie5 from "assets/image/Movie5.png";
import Movie6 from "assets/image/Movie6.png";
import Movie7 from "assets/image/Movie7.png";
import Movie8 from "assets/image/Movie8.png";
import Movie9 from "assets/image/Movie9.png";
import Movie10 from "assets/image/Movie10.png";
import Movie11 from "assets/image/Movie11.png";
import Movie12 from "assets/image/Movie12.png";
import BestContent1 from "assets/image/BestContent1.png";
import BestContent2 from "assets/image/BestContent2.png";
import BestContent3 from "assets/image/BestContent3.png";
import BestContent4 from "assets/image/BestContent4.png";
import BestContent5 from "assets/image/BestContent5.png";
import BestContent6 from "assets/image/BestContent6.png";
import SocialBack from "assets/image/SocialBack.png";
// import SArrow from "assets/svg/SArrow.svg";
import { Link } from "react-router-dom";
import Calendar from "assets/svg/Calendar.svg";
import Head from "assets/image/Head.png";
import WBubble from "assets/image/WBubble.png";
import Kakao from "assets/image/Kakao.png";
import Ad from "assets/svg/Ad.svg";
import Thumb from "assets/svg/Thumb.svg";
import Facebook from "assets/svg/Facebook.svg";
import BlogIcon from "assets/image/BlogIcon.png";
import Social from "assets/image/SocialLouder.png";
import RedCircle from "assets/svg/RedCircle.svg";
import WhiteCircle from "assets/svg/WhiteCircle.svg";
import Play from "assets/svg/Play.svg";
import Pause from "assets/svg/Pause.svg";

export const SocialLouder = () => {
  return (
    <div className="flex flex-col items-center justify-center font-noto whitespace-nowrap">
      <div className="flex items-center justify-center gap-26 mt-123 text-[#2c2c2c]">
        <img src={Logo} alt="Logo" />
        <div className="flex">
          <img src={Bubble} className="absolute" alt="speech bubble" />
          <p className="relative top-18 text-14 ml-38 mr-22">
            공지: 쇼셜라우더가 확~ 달려졌습니다!
          </p>
        </div>
      </div>

      <div className="w-1200 flex justify-center items-center text-[#2c2c2c] cursor-pointer text-18 gap-34 h-100 py-35 mt-13">
        <div className="flex items-center justify-center">
          <p>전체</p>
          <img src={BArrow} alt="BackArrow" />
        </div>
        <div className="relative flex items-center">
          <div className="flex items-center justify-center">
            <p className="text-[#f29e39]">진행 캠페인</p>
            <img src={TArrow} alt="TopArrow" />
            {/* {showSubMenu && (
              <div className="absolute z-10 pt-10 pl-10 bg-white shadow-md text-18 w-148 h-148 rounded-25 border-light-gray5 border-1 top-50">
                <p className="flex gap-40 m-15">
                  블로그
                  <img src={SArrow} alt="SArrows" />
                </p>
                <p className="text-[#f29e39] m-15 flex gap-40">
                  인스타
                  <img src={SArrow} alt="SArrows" />
                </p>
                <p className="flex m-15 gap-55">
                  숏폼
                  <img src={SArrow} alt="SArrows" />
                </p>
              </div>
            )} */}
          </div>
        </div>
        <p>마감 캠페인</p>
        <p>콘텐츠</p>
        <p>챌린지</p>
        <div className="flex items-center">
          <p>커뮤니티</p>
          <img src={BArrow} alt="BackArrow" />
        </div>

        <div className="flex items-center gap-22 text-14 text-[#606060]">
          <Link to="/homepage/join-colored">
            <img src={Search} className="mr-20 ml-240" alt="Search" />
          </Link>
          <Link to="/homepage/login">
            <div className="flex gap-7">
              <img src={Lock} alt="Lock" />
              <p>로그인</p>
            </div>
          </Link>
          <Link to="/homepage/join">
            <div className="flex gap-7">
              <img src={Sign} alt="Sign" />
              <p>회원가입</p>
            </div>
          </Link>
          <div className="ml-28 border-1 border-[#e6a454] rounded-4 h-32 text-black text-14 w-126 bg-[#feba69] text-center py-6">
            캠페인 오픈하기
          </div>
        </div>
      </div>

      {/* <div className="flex w-full h-67 bg-sub-banner text-17 pt-23 pb-19">
        <div className="flex items-center justify-end w-1200 gap-38">
          <p>박스 오피스</p>
          <p>넷플릭스</p>
          <p>디즈니+</p>
          <p>쿠팡플레이</p>
          <p>티빙</p>
        </div>
      </div> */}

      <div className="relative flex items-center justify-center w-full h-471 bg-main-banner border-t-1 border-logo">
        <img src={Banner} alt="Banner" />
        <div className="absolute">
          <div className="flex gap-10 mt-420">
            <img src={RedCircle} alt="RedCircle" />
            <img src={WhiteCircle} alt="WhiteCircle" />
            <img src={Play} alt="Play" />
          </div>
          <img src={Pause} className="ml-45" alt="Pause" />
        </div>
      </div>

      <div className="flex mt-58 gap-125">
        <div className="flex flex-col gap-10 text-center text-16">
          <img src={Movie} className="w-140" alt="Movie" />
          <span>Movie</span>
        </div>
        <div className="flex flex-col gap-10 text-center text-16">
          <img src={Series} className="w-140" alt="Series" />
          <span>Series</span>
        </div>
        <div className="flex flex-col gap-10 text-center text-16">
          <img src={Entertainment} className="w-140" alt="Entertainment" />
          <span>Entertainment</span>
        </div>
        <div className="flex flex-col gap-10 text-center text-16">
          <img src={Show} className="w-140" alt="Show" />
          <span>Show / Musical</span>
        </div>
        <div className="flex flex-col gap-10 text-center text-16">
          <img src={Book} className="w-140" alt="Book" />
          <span>Book / web novel</span>
        </div>
      </div>

      <div className="flex mt-56 w-1200 bg-small-banner rounded-25 mb-74">
        <img src={BannerLogo} className="mr-16 -mt-30 ml-42" alt="BannerLogo" />
        <p className="flex items-center font-bold tracking-tighter text-30 mr-93">
          문화콘텐츠 SNS 기사단
          <br /> 쇼셜 라우더
        </p>

        <div className="flex flex-col items-center justify-center gap-10">
          <img src={Blog} alt="Blog" />
          <p className="text-16">블로거</p>
          <p className="font-semibold text-26 text-orange2">5,728</p>
        </div>
        <div className="mx-33 mt-45 h-120 border-r-1 border-social-hr"></div>
        <div className="flex flex-col items-center justify-center gap-10">
          <img src={Insta} alt="Insta" />
          <p className="text-16">인스타 인플루언서</p>
          <p className="font-semibold text-26 text-orange2">1,385</p>
        </div>
        <div className="mx-33 mt-45 h-120 border-r-1 border-social-hr"></div>
        <div className="flex flex-col items-center justify-center gap-10">
          <img src={Campaign} alt="Campaign" />
          <p className="text-16">캠페인</p>
          <p className="font-semibold text-26 text-orange2">720</p>
        </div>
        <div className="mx-33 mt-45 h-120 border-r-1 border-social-hr"></div>
        <div className="flex flex-col items-center justify-center gap-10">
          <img src={Review} alt="Review" />
          <p className="text-16">리뷰</p>
          <p className="font-semibold text-26 text-orange2">26,166</p>
        </div>
      </div>

      <div className="flex flex-col gap-10 w-1200">
        <div className="flex gap-8">
          <p className="flex font-bold text-24">신청 가능 리뷰</p>
          <img src={Question} alt="Question" />
        </div>
        <span className="text-14 text-light-gray6">
          영상매체를 통해 리뷰가 가능한 콘텐츠입니다.
        </span>
      </div>

      <div className="grid grid-cols-4 gap-19 w-1200">
        <Link to="/homepage/detail">
          <div className="relative mt-24 text-white">
            <img
              src={Movie1}
              alt="Movie Poster"
              className="w-286 h-407 rounded-28"
            />
            <div className="absolute top-0 flex mt-15 gap-99">
              <div className="flex items-center justify-center gap-4 ml-15 bg-black-opacity-20 rounded-15 w-87 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">마감 D-4</span>
              </div>
              <div className="flex items-center justify-center gap-4 bg-orange-opacity-90 rounded-15 w-72 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">10,000</span>
              </div>
            </div>
            <div className="absolute bottom-0 w-full pt-16 px-14 bg-black-opacity-20 h-186 rounded-b-28">
              <h1 className="mb-8 font-bold text-16">
                [Blog] 영화 &lt;히말라야&gt; 기대리뷰
              </h1>
              <p className="tracking-tight text-11">
                걱정마 우리가 꼭 찾아갈께! 그게 인간이 갈 수 없는곳이라도..
              </p>
              <div className="relative flex mt-14">
                <img src={Head} className="mr-13" alt="Logo" />
                <img src={WBubble} alt="bubble" />
                <div className="absolute w-full h-full left-130 top-7">
                  <span className="font-bold text-red text-24">
                    970
                    <span className="ml-3 text-18">%</span>
                  </span>
                </div>
              </div>

              <div className="flex mt-22 justify-evenly leading-17">
                <div className="flex flex-col">
                  <p className="text-11">지원자</p>
                  <p className="font-bold text-16 text-orange3">97명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1 "></div>
                <div className="flex flex-col">
                  <p className="text-11">모집인원</p>
                  <p className="font-bold text-16 text-orange3">10명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1"></div>
                <div className="flex flex-col">
                  <p className="text-11">신청마감</p>
                  <p className="font-bold text-16 text-orange3">02.25</p>
                </div>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/homepage/detail">
          <div className="relative mt-24 text-white">
            <img
              src={Movie2}
              alt="Movie Poster"
              className="w-286 h-407 rounded-28"
            />
            <div className="absolute top-0 flex mt-15 gap-99">
              <div className="flex items-center justify-center gap-4 ml-15 bg-black-opacity-20 rounded-15 w-87 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">마감 D-4</span>
              </div>
              <div className="flex items-center justify-center gap-4 bg-orange-opacity-90 rounded-15 w-72 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">10,000</span>
              </div>
            </div>
            <div className="absolute bottom-0 w-full pt-16 px-14 bg-black-opacity-20 h-186 rounded-b-28">
              <h1 className="mb-8 font-bold text-16">
                [Blog] 영화 &lt;히말라야&gt; 기대리뷰
              </h1>
              <p className="tracking-tight text-11">
                걱정마 우리가 꼭 찾아갈께! 그게 인간이 갈 수 없는곳이라도..
              </p>
              <div className="relative flex mt-14">
                <img src={Head} className="mr-13" alt="Logo" />
                <img src={WBubble} alt="bubble" />
                <div className="absolute w-full h-full left-130 top-7">
                  <span className="font-bold text-red text-24">
                    970
                    <span className="ml-3 text-18">%</span>
                  </span>
                </div>
              </div>

              <div className="flex mt-22 justify-evenly leading-17">
                <div className="flex flex-col">
                  <p className="text-11">지원자</p>
                  <p className="font-bold text-16 text-orange3">97명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1 "></div>
                <div className="flex flex-col">
                  <p className="text-11">모집인원</p>
                  <p className="font-bold text-16 text-orange3">10명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1"></div>
                <div className="flex flex-col">
                  <p className="text-11">신청마감</p>
                  <p className="font-bold text-16 text-orange3">02.25</p>
                </div>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/homepage/detail">
          <div className="relative mt-24 text-white">
            <img
              src={Movie3}
              alt="Movie Poster"
              className="w-286 h-407 rounded-28"
            />
            <div className="absolute top-0 flex mt-15 gap-99">
              <div className="flex items-center justify-center gap-4 ml-15 bg-black-opacity-20 rounded-15 w-87 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">마감 D-4</span>
              </div>
              <div className="flex items-center justify-center gap-4 bg-orange-opacity-90 rounded-15 w-72 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">10,000</span>
              </div>
            </div>
            <div className="absolute bottom-0 w-full pt-16 px-14 bg-black-opacity-20 h-186 rounded-b-28">
              <h1 className="mb-8 font-bold text-16">
                [Blog] 영화 &lt;히말라야&gt; 기대리뷰
              </h1>
              <p className="tracking-tight text-11">
                걱정마 우리가 꼭 찾아갈께! 그게 인간이 갈 수 없는곳이라도..
              </p>
              <div className="relative flex mt-14">
                <img src={Head} className="mr-13" alt="Logo" />
                <img src={WBubble} alt="bubble" />
                <div className="absolute w-full h-full left-130 top-7">
                  <span className="font-bold text-red text-24">
                    970
                    <span className="ml-3 text-18">%</span>
                  </span>
                </div>
              </div>

              <div className="flex mt-22 justify-evenly leading-17">
                <div className="flex flex-col">
                  <p className="text-11">지원자</p>
                  <p className="font-bold text-16 text-orange3">97명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1 "></div>
                <div className="flex flex-col">
                  <p className="text-11">모집인원</p>
                  <p className="font-bold text-16 text-orange3">10명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1"></div>
                <div className="flex flex-col">
                  <p className="text-11">신청마감</p>
                  <p className="font-bold text-16 text-orange3">02.25</p>
                </div>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/homepage/detail">
          <div className="relative mt-24 text-white">
            <img
              src={Movie4}
              alt="Movie Poster"
              className="w-286 h-407 rounded-28"
            />
            <div className="absolute top-0 flex mt-15 gap-99">
              <div className="flex items-center justify-center gap-4 ml-15 bg-black-opacity-20 rounded-15 w-87 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">마감 D-4</span>
              </div>
              <div className="flex items-center justify-center gap-4 bg-orange-opacity-90 rounded-15 w-72 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">10,000</span>
              </div>
            </div>
            <div className="absolute bottom-0 w-full pt-16 px-14 bg-black-opacity-20 h-186 rounded-b-28">
              <h1 className="mb-8 font-bold text-16">
                [Blog] 영화 &lt;히말라야&gt; 기대리뷰
              </h1>
              <p className="tracking-tight text-11">
                걱정마 우리가 꼭 찾아갈께! 그게 인간이 갈 수 없는곳이라도..
              </p>
              <div className="relative flex mt-14">
                <img src={Head} className="mr-13" alt="Logo" />
                <img src={WBubble} alt="bubble" />
                <div className="absolute w-full h-full left-130 top-7">
                  <span className="font-bold text-red text-24">
                    970
                    <span className="ml-3 text-18">%</span>
                  </span>
                </div>
              </div>

              <div className="flex mt-22 justify-evenly leading-17">
                <div className="flex flex-col">
                  <p className="text-11">지원자</p>
                  <p className="font-bold text-16 text-orange3">97명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1 "></div>
                <div className="flex flex-col">
                  <p className="text-11">모집인원</p>
                  <p className="font-bold text-16 text-orange3">10명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1"></div>
                <div className="flex flex-col">
                  <p className="text-11">신청마감</p>
                  <p className="font-bold text-16 text-orange3">02.25</p>
                </div>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/homepage/detail">
          <div className="relative mt-24 text-white">
            <img
              src={Movie5}
              alt="Movie Poster"
              className="w-286 h-407 rounded-28"
            />
            <div className="absolute top-0 flex mt-15 gap-99">
              <div className="flex items-center justify-center gap-4 ml-15 bg-black-opacity-20 rounded-15 w-87 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">마감 D-4</span>
              </div>
              <div className="flex items-center justify-center gap-4 bg-orange-opacity-90 rounded-15 w-72 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">10,000</span>
              </div>
            </div>
            <div className="absolute bottom-0 w-full pt-16 px-14 bg-black-opacity-20 h-186 rounded-b-28">
              <h1 className="mb-8 font-bold text-16">
                [Blog] 영화 &lt;히말라야&gt; 기대리뷰
              </h1>
              <p className="tracking-tight text-11">
                걱정마 우리가 꼭 찾아갈께! 그게 인간이 갈 수 없는곳이라도..
              </p>
              <div className="relative flex mt-14">
                <img src={Head} className="mr-13" alt="Logo" />
                <img src={WBubble} alt="bubble" />
                <div className="absolute w-full h-full left-130 top-7">
                  <span className="font-bold text-red text-24">
                    970
                    <span className="ml-3 text-18">%</span>
                  </span>
                </div>
              </div>

              <div className="flex mt-22 justify-evenly leading-17">
                <div className="flex flex-col">
                  <p className="text-11">지원자</p>
                  <p className="font-bold text-16 text-orange3">97명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1 "></div>
                <div className="flex flex-col">
                  <p className="text-11">모집인원</p>
                  <p className="font-bold text-16 text-orange3">10명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1"></div>
                <div className="flex flex-col">
                  <p className="text-11">신청마감</p>
                  <p className="font-bold text-16 text-orange3">02.25</p>
                </div>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/homepage/detail">
          <div className="relative mt-24 text-white">
            <img
              src={Movie6}
              alt="Movie Poster"
              className="w-286 h-407 rounded-28"
            />
            <div className="absolute top-0 flex mt-15 gap-99">
              <div className="flex items-center justify-center gap-4 ml-15 bg-black-opacity-20 rounded-15 w-87 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">마감 D-4</span>
              </div>
              <div className="flex items-center justify-center gap-4 bg-orange-opacity-90 rounded-15 w-72 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">10,000</span>
              </div>
            </div>
            <div className="absolute bottom-0 w-full pt-16 px-14 bg-black-opacity-20 h-186 rounded-b-28">
              <h1 className="mb-8 font-bold text-16">
                [Blog] 영화 &lt;히말라야&gt; 기대리뷰
              </h1>
              <p className="tracking-tight text-11">
                걱정마 우리가 꼭 찾아갈께! 그게 인간이 갈 수 없는곳이라도..
              </p>
              <div className="relative flex mt-14">
                <img src={Head} className="mr-13" alt="Logo" />
                <img src={WBubble} alt="bubble" />
                <div className="absolute w-full h-full left-130 top-7">
                  <span className="font-bold text-red text-24">
                    970
                    <span className="ml-3 text-18">%</span>
                  </span>
                </div>
              </div>

              <div className="flex mt-22 justify-evenly leading-17">
                <div className="flex flex-col">
                  <p className="text-11">지원자</p>
                  <p className="font-bold text-16 text-orange3">97명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1 "></div>
                <div className="flex flex-col">
                  <p className="text-11">모집인원</p>
                  <p className="font-bold text-16 text-orange3">10명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1"></div>
                <div className="flex flex-col">
                  <p className="text-11">신청마감</p>
                  <p className="font-bold text-16 text-orange3">02.25</p>
                </div>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/homepage/detail">
          <div className="relative mt-24 text-white">
            <img
              src={Movie7}
              alt="Movie Poster"
              className="w-286 h-407 rounded-28"
            />
            <div className="absolute top-0 flex mt-15 gap-99">
              <div className="flex items-center justify-center gap-4 ml-15 bg-black-opacity-20 rounded-15 w-87 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">마감 D-4</span>
              </div>
              <div className="flex items-center justify-center gap-4 bg-orange-opacity-90 rounded-15 w-72 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">10,000</span>
              </div>
            </div>
            <div className="absolute bottom-0 w-full pt-16 px-14 bg-black-opacity-20 h-186 rounded-b-28">
              <h1 className="mb-8 font-bold text-16">
                [Blog] 영화 &lt;히말라야&gt; 기대리뷰
              </h1>
              <p className="tracking-tight text-11">
                걱정마 우리가 꼭 찾아갈께! 그게 인간이 갈 수 없는곳이라도..
              </p>
              <div className="relative flex mt-14">
                <img src={Head} className="mr-13" alt="Logo" />
                <img src={WBubble} alt="bubble" />
                <div className="absolute w-full h-full left-130 top-7">
                  <span className="font-bold text-red text-24">
                    970
                    <span className="ml-3 text-18">%</span>
                  </span>
                </div>
              </div>

              <div className="flex mt-22 justify-evenly leading-17">
                <div className="flex flex-col">
                  <p className="text-11">지원자</p>
                  <p className="font-bold text-16 text-orange3">97명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1 "></div>
                <div className="flex flex-col">
                  <p className="text-11">모집인원</p>
                  <p className="font-bold text-16 text-orange3">10명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1"></div>
                <div className="flex flex-col">
                  <p className="text-11">신청마감</p>
                  <p className="font-bold text-16 text-orange3">02.25</p>
                </div>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/homepage/detail">
          <div className="relative mt-24 text-white">
            <img
              src={Movie8}
              alt="Movie Poster"
              className="w-286 h-407 rounded-28"
            />
            <div className="absolute top-0 flex mt-15 gap-99">
              <div className="flex items-center justify-center gap-4 ml-15 bg-black-opacity-20 rounded-15 w-87 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">마감 D-4</span>
              </div>
              <div className="flex items-center justify-center gap-4 bg-orange-opacity-90 rounded-15 w-72 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">10,000</span>
              </div>
            </div>
            <div className="absolute bottom-0 w-full pt-16 px-14 bg-black-opacity-20 h-186 rounded-b-28">
              <h1 className="mb-8 font-bold text-16">
                [Blog] 영화 &lt;히말라야&gt; 기대리뷰
              </h1>
              <p className="tracking-tight text-11">
                걱정마 우리가 꼭 찾아갈께! 그게 인간이 갈 수 없는곳이라도..
              </p>
              <div className="relative flex mt-14">
                <img src={Head} className="mr-13" alt="Logo" />
                <img src={WBubble} alt="bubble" />
                <div className="absolute w-full h-full left-130 top-7">
                  <span className="font-bold text-red text-24">
                    970
                    <span className="ml-3 text-18">%</span>
                  </span>
                </div>
              </div>

              <div className="flex mt-22 justify-evenly leading-17">
                <div className="flex flex-col">
                  <p className="text-11">지원자</p>
                  <p className="font-bold text-16 text-orange3">97명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1 "></div>
                <div className="flex flex-col">
                  <p className="text-11">모집인원</p>
                  <p className="font-bold text-16 text-orange3">10명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1"></div>
                <div className="flex flex-col">
                  <p className="text-11">신청마감</p>
                  <p className="font-bold text-16 text-orange3">02.25</p>
                </div>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/homepage/detail">
          <div className="relative mt-24 text-white">
            <img
              src={Movie9}
              alt="Movie Poster"
              className="w-286 h-407 rounded-28"
            />
            <div className="absolute top-0 flex mt-15 gap-99">
              <div className="flex items-center justify-center gap-4 ml-15 bg-black-opacity-20 rounded-15 w-87 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">마감 D-4</span>
              </div>
              <div className="flex items-center justify-center gap-4 bg-orange-opacity-90 rounded-15 w-72 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">10,000</span>
              </div>
            </div>
            <div className="absolute bottom-0 w-full pt-16 px-14 bg-black-opacity-20 h-186 rounded-b-28">
              <h1 className="mb-8 font-bold text-16">
                [Blog] 영화 &lt;히말라야&gt; 기대리뷰
              </h1>
              <p className="tracking-tight text-11">
                걱정마 우리가 꼭 찾아갈께! 그게 인간이 갈 수 없는곳이라도..
              </p>
              <div className="relative flex mt-14">
                <img src={Head} className="mr-13" alt="Logo" />
                <img src={WBubble} alt="bubble" />
                <div className="absolute w-full h-full left-130 top-7">
                  <span className="font-bold text-red text-24">
                    970
                    <span className="ml-3 text-18">%</span>
                  </span>
                </div>
              </div>

              <div className="flex mt-22 justify-evenly leading-17">
                <div className="flex flex-col">
                  <p className="text-11">지원자</p>
                  <p className="font-bold text-16 text-orange3">97명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1 "></div>
                <div className="flex flex-col">
                  <p className="text-11">모집인원</p>
                  <p className="font-bold text-16 text-orange3">10명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1"></div>
                <div className="flex flex-col">
                  <p className="text-11">신청마감</p>
                  <p className="font-bold text-16 text-orange3">02.25</p>
                </div>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/homepage/detail">
          <div className="relative mt-24 text-white">
            <img
              src={Movie10}
              alt="Movie Poster"
              className="w-286 h-407 rounded-28"
            />
            <div className="absolute top-0 flex mt-15 gap-99">
              <div className="flex items-center justify-center gap-4 ml-15 bg-black-opacity-20 rounded-15 w-87 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">마감 D-4</span>
              </div>
              <div className="flex items-center justify-center gap-4 bg-orange-opacity-90 rounded-15 w-72 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">10,000</span>
              </div>
            </div>
            <div className="absolute bottom-0 w-full pt-16 px-14 bg-black-opacity-20 h-186 rounded-b-28">
              <h1 className="mb-8 font-bold text-16">
                [Blog] 영화 &lt;히말라야&gt; 기대리뷰
              </h1>
              <p className="tracking-tight text-11">
                걱정마 우리가 꼭 찾아갈께! 그게 인간이 갈 수 없는곳이라도..
              </p>
              <div className="relative flex mt-14">
                <img src={Head} className="mr-13" alt="Logo" />
                <img src={WBubble} alt="bubble" />
                <div className="absolute w-full h-full left-130 top-7">
                  <span className="font-bold text-red text-24">
                    970
                    <span className="ml-3 text-18">%</span>
                  </span>
                </div>
              </div>

              <div className="flex mt-22 justify-evenly leading-17">
                <div className="flex flex-col">
                  <p className="text-11">지원자</p>
                  <p className="font-bold text-16 text-orange3">97명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1 "></div>
                <div className="flex flex-col">
                  <p className="text-11">모집인원</p>
                  <p className="font-bold text-16 text-orange3">10명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1"></div>
                <div className="flex flex-col">
                  <p className="text-11">신청마감</p>
                  <p className="font-bold text-16 text-orange3">02.25</p>
                </div>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/homepage/detail">
          <div className="relative mt-24 text-white">
            <img
              src={Movie11}
              alt="Movie Poster"
              className="w-286 h-407 rounded-28"
            />
            <div className="absolute top-0 flex mt-15 gap-99">
              <div className="flex items-center justify-center gap-4 ml-15 bg-black-opacity-20 rounded-15 w-87 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">마감 D-4</span>
              </div>
              <div className="flex items-center justify-center gap-4 bg-orange-opacity-90 rounded-15 w-72 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">10,000</span>
              </div>
            </div>
            <div className="absolute bottom-0 w-full pt-16 px-14 bg-black-opacity-20 h-186 rounded-b-28">
              <h1 className="mb-8 font-bold text-16">
                [Blog] 영화 &lt;히말라야&gt; 기대리뷰
              </h1>
              <p className="tracking-tight text-11">
                걱정마 우리가 꼭 찾아갈께! 그게 인간이 갈 수 없는곳이라도..
              </p>
              <div className="relative flex mt-14">
                <img src={Head} className="mr-13" alt="Logo" />
                <img src={WBubble} alt="bubble" />
                <div className="absolute w-full h-full left-130 top-7">
                  <span className="font-bold text-red text-24">
                    970
                    <span className="ml-3 text-18">%</span>
                  </span>
                </div>
              </div>

              <div className="flex mt-22 justify-evenly leading-17">
                <div className="flex flex-col">
                  <p className="text-11">지원자</p>
                  <p className="font-bold text-16 text-orange3">97명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1 "></div>
                <div className="flex flex-col">
                  <p className="text-11">모집인원</p>
                  <p className="font-bold text-16 text-orange3">10명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1"></div>
                <div className="flex flex-col">
                  <p className="text-11">신청마감</p>
                  <p className="font-bold text-16 text-orange3">02.25</p>
                </div>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/homepage/detail">
          <div className="relative mt-24 text-white">
            <img
              src={Movie12}
              alt="Movie Poster"
              className="w-286 h-407 rounded-28"
            />
            <div className="absolute top-0 flex mt-15 gap-99">
              <div className="flex items-center justify-center gap-4 ml-15 bg-black-opacity-20 rounded-15 w-87 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">마감 D-4</span>
              </div>
              <div className="flex items-center justify-center gap-4 bg-orange-opacity-90 rounded-15 w-72 h-26">
                <img src={Calendar} className="" alt="Calendar" />
                <span className="text-center text-12">10,000</span>
              </div>
            </div>
            <div className="absolute bottom-0 w-full pt-16 px-14 bg-black-opacity-20 h-186 rounded-b-28">
              <h1 className="mb-8 font-bold text-16">
                [Blog] 영화 &lt;히말라야&gt; 기대리뷰
              </h1>
              <p className="tracking-tight text-11">
                걱정마 우리가 꼭 찾아갈께! 그게 인간이 갈 수 없는곳이라도..
              </p>
              <div className="relative flex mt-14">
                <img src={Head} className="mr-13" alt="Logo" />
                <img src={WBubble} alt="bubble" />
                <div className="absolute w-full h-full left-130 top-7">
                  <span className="font-bold text-red text-24">
                    970
                    <span className="ml-3 text-18">%</span>
                  </span>
                </div>
              </div>

              <div className="flex mt-22 justify-evenly leading-17">
                <div className="flex flex-col">
                  <p className="text-11">지원자</p>
                  <p className="font-bold text-16 text-orange3">97명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1 "></div>
                <div className="flex flex-col">
                  <p className="text-11">모집인원</p>
                  <p className="font-bold text-16 text-orange3">10명</p>
                </div>
                <div className="h-32 border-white-opacity-40 border-r-1"></div>
                <div className="flex flex-col">
                  <p className="text-11">신청마감</p>
                  <p className="font-bold text-16 text-orange3">02.25</p>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>

      <div className="mt-56 text-center cursor-pointer mb-78 w-260 h-52 bg-light-gray rounded-43 text-18 py-13">
        더 보기
      </div>

      <div className="w-full bg-light-gray">
        <div className="flex flex-col gap-10 mx-auto w-1200">
          <div>
            <div className="flex gap-8 mt-69">
              <p className="flex mb-10 font-bold text-24">베스트 콘텐츠</p>
              <img src={Question} alt="Question" />
            </div>
            <span className="text-14 text-light-gray6">
              인기와 반응이 높았던 영상 콘텐츠입니다.
            </span>
          </div>
          <div className="grid grid-cols-2 mt-24 gap-19">
            <div className="flex pl-24 tracking-tight bg-white break-keep gap-18 w-591 h-214 rounded-28 py-19">
              <img src={BestContent1} alt="Main" />
              <div className="flex flex-col gap-6 pr-24">
                <img src={Blog} className="w-40" alt="Icon" />
                <h1 className="font-bold whitespace-normal text-16 leading-22">
                  [메가박스] 코엑스 MX4D 다이나믹한 관람 후기! 다양한 효과로
                  더해지는 꿀잼!
                </h1>
                <p className="whitespace-normal leading-18 text-13">
                  [Blog] 메가박스 코엑스 MEGA l MX4D 신규 오픈 프리뷰 시사회
                  프리미엄 후기 리뷰
                </p>
                <p className="text-12 text-light-gray6">작성자: ggulggul</p>
                <p className="text-12 text-light-gray6">
                  베스트 선정일자: 2024.02.20
                </p>
              </div>
            </div>

            <div className="flex pl-24 tracking-tight bg-white break-keep gap-18 w-591 h-214 rounded-28 py-19">
              <img src={BestContent2} alt="Main" />
              <div className="flex flex-col gap-6 pr-24">
                <img src={Youtube} className="w-40" alt="Icon" />
                <h1 className="font-bold whitespace-normal text-16 leading-22">
                  [극장판 츠루네] 기대의 한발, 한국 개봉 확정 기대리뷰.
                </h1>
                <p className="whitespace-normal leading-18 text-13">
                  [youtube] 영호&lt;극장판 츠루네: 시작의 한 발&gt; 2차 기대
                  리뷰
                </p>
                <p className="text-12 text-light-gray6">작성자: 츠아</p>
                <p className="text-12 text-light-gray6">
                  베스트 선정일자: 2024.02.20
                </p>
              </div>
            </div>

            <div className="flex pl-24 tracking-tight bg-white break-keep gap-18 w-591 h-214 rounded-28 py-19">
              <img src={BestContent3} alt="Main" />
              <div className="flex flex-col gap-6 pr-24">
                <img src={Insta} className="w-40" alt="Icon" />
                <h1 className="font-bold whitespace-normal text-16 leading-22">
                  [검은 소년] 안지호, 안내상, 윤유선 주연 영화 예고편, 개봉일
                  정보
                </h1>
                <p className="whitespace-normal leading-18 text-13">
                  [Blog] 영화 &lt;검은 소년&gt; 기대 리뷰
                </p>
                <p className="text-12 text-light-gray6">작성자: 라나</p>
                <p className="text-12 text-light-gray6">
                  베스트 선정일자: 2024.02.20
                </p>
              </div>
            </div>

            <div className="flex pl-24 tracking-tight bg-white break-keep gap-18 w-591 h-214 rounded-28 py-19">
              <img src={BestContent4} alt="Main" />
              <div className="flex flex-col gap-6 pr-24">
                <img src={Blog} className="w-40" alt="Icon" />
                <h1 className="font-bold whitespace-normal text-16 leading-22">
                  [메가박스 송도 돌비 시네마] 개관 시사회 영화 윙카 체험 후기,
                  송돌비 애트모스 Dolby Atoms ..
                </h1>
                <p className="whitespace-normal leading-18 text-13">
                  [Blog] 메가박스 송도 돌비 시네마 특별 시사회 프리미엄 후기
                  리뷰
                </p>
                <p className="text-12 text-light-gray6">작성자: 팝콘</p>
                <p className="text-12 text-light-gray6">
                  베스트 선정일자: 2024.02.20
                </p>
              </div>
            </div>

            <div className="flex pl-24 tracking-tight bg-white break-keep gap-18 w-591 h-214 rounded-28 py-19">
              <img src={BestContent5} alt="Main" />
              <div className="flex flex-col gap-6 pr-24">
                <img src={Insta} className="w-40" alt="Icon" />
                <h1 className="font-bold whitespace-normal text-16 leading-22">
                  [검은 소년] 안지호, 안내상, 윤유선 주연 영화 예고편, 개봉일
                  정보
                </h1>
                <p className="whitespace-normal leading-18 text-13">
                  [Blog] 영화 &lt;검은 소년&gt; 기대 리뷰
                </p>
                <p className="text-12 text-light-gray6">작성자: 라나</p>
                <p className="text-12 text-light-gray6">
                  베스트 선정일자: 2024.02.20
                </p>
              </div>
            </div>

            <div className="flex pl-24 tracking-tight bg-white break-keep gap-18 w-591 h-214 rounded-28 py-19">
              <img src={BestContent6} alt="Main" />
              <div className="flex flex-col gap-6 pr-24">
                <img src={Blog} className="w-40" alt="Icon" />
                <h1 className="font-bold whitespace-normal text-16 leading-22">
                  [메가박스 송도 돌비 시네마] 개관 시사회 영화 윙카 체험 후기,
                  송돌비 애트모스 Dolby Atoms ..
                </h1>
                <p className="whitespace-normal leading-18 text-13">
                  [Blog] 메가박스 송도 돌비 시네마 특별 시사회 프리미엄 후기
                  리뷰
                </p>
                <p className="text-12 text-light-gray6">작성자: 팝콘</p>
                <p className="text-12 text-light-gray6">
                  베스트 선정일자: 2024.02.20
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="flex items-center justify-center mt-56 text-center cursor-pointer mb-76 w-260 h-52 bg-gray3 rounded-43 text-18 py-13">
              더 보기
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-logo pt-33">
        <img
          src={SocialBack}
          className="absolute w-full h-276"
          alt="SocialBack"
        />
        <div className="flex items-center justify-center">
          <img
            src={BigBannerLogo}
            className="relative mr-24"
            alt="BannerLogo"
          />
          <p className="z-10 text-30 leading-43">
            <span className="text-50">지</span>금 비즈니스 회원으로 가입하면,
            <br /> 직접 캠페인을 등록할 수 있어요.
            <br /> 손쉽게 캠페인을 오픈하고, 효율적으로
            <br /> 인플루언서를 모집하세요.
          </p>
          <div className="z-10 text-center text-white bg-black cursor-pointer ml-66 w-339 text-27 py-33 rounded-25">
            비지니스 회원가입하기
          </div>
        </div>
      </div>

      <div className="w-full">
        <div className="flex justify-center bg-footer h-480">
          <div className="flex items-center justify-between w-1200">
            <div className="flex flex-col">
              <img src={Social} className="w-140" alt="SocialLouder" />
              <div className="flex text-white mt-43">
                <img src={Kakao} alt="Kakao" />
                <p className="text-16 ml-7">
                  쇼셜라우더 카카오톡 운영시간: 10:00~17:00 (주말 공휴일 휴무)
                </p>
              </div>
              <p className="mt-32 tracking-wider text-14 text-light-gray7 leading-23">
                서울특별시 강남구 도산대로59길 26, 2층(청담동, 히든힐하우스)
                <br />
                help@sociallouder.co.kr
              </p>
              <p className="mt-10 text-14 text-light-gray7 leading-23">
                사업자등록번호 : 658-88-02426 [사업자확인]
                <br /> 상호 : 주식회사 소셜라우더 | 대표자 : 최동인, 손나래
                <br />
                개인정보보호책임자 : 김하늘
              </p>
            </div>

            <div className="flex flex-col mt-105 text-15">
              <div className="flex items-center ml-30">
                <img src={Ad} alt="Ad" />
                <p className="text-white ml-7">쇼셜라우더 광고 문의</p>
                <div className="h-40 text-center cursor-pointer ml-86 border-1 border-black4 w-210 bg-black3 text-logo rounded-8 py-9">
                  플랫폼 제휴 문의하기
                </div>
              </div>

              <div className="flex items-center justify-center ml-30 ">
                <img src={Thumb} className=" mt-18" alt="Thumb" />
                <p className="ml-3 text-white mt-18 mr-28">
                  맞춤형 인플루언서 마케팅 문의
                </p>
                <div className="h-40 text-center cursor-pointer mt-22 border-1 border-black4 w-210 bg-black3 text-logo rounded-8 py-9">
                  맞춤 인플루언서 마케팅 문의
                </div>
              </div>

              <div className="flex items-center cursor-pointer border-y-1 border-light-gray8 py-14 mt-14 text-light-gray7">
                <p className="mr-60">
                  소셜라우더 BIZ 광고 보기 ㅣ 쇼설라우더 제안서 보기
                </p>
                <img src={Insta} className="w-27 mr-9" alt="Insta" />
                <img src={Facebook} className="w-27 mr-9" alt="Facebook" />
                <img src={BlogIcon} className="w-27" alt="BlogIcon" />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full bg-light-gray8 text-14 text-light-gray9">
          <div className="flex justify-between m-auto h-81 pt-30 w-1200">
            <p>
              Copyright © 2021 소셜라우더 - 영화SNS 블로그체험단 Inc. All rights
              reserved
            </p>
            <p className="cursor-pointer">
              회사소개 ㅣ 이용약관 ㅣ 개인정보처리방침
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
