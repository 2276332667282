export const Slogan = () => {
  return (
    <div className="flex flex-col items-center justify-center w-screen gap-[0.31vw] bg-black h-[14.53vw]">
      <h4 className="font-bold text-white text-[1.71vw]">
        WE PROMISE WITH OUR PRIDE
      </h4>
      <span className="text-dark-gray">
        CREATE A SUCCESSFUL BRANDING AND DIGITAL PERFORMANCE
      </span>
    </div>
  );
};
