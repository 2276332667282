import BlogImg1 from "assets/image/BlogImg1.png";
import BlogImg2 from "assets/image/BlogImg2.png";
import BlogImg3 from "assets/image/BlogImg3.png";
import BlogImg4 from "assets/image/BlogImg4.png";

export const Marketing = () => {
  return (
    <div className="max-w-1200 w-[46.87vw] h-[26.91vw]">
      <p className="text-orange leading-[1.17vw] mt-[4.29vw]">
        효율적 블로그 마케팅 어떻게 시작할지 막막하다면?
      </p>
      <h4 className="mt-[0.31vw] font-bold tracking-tightest text-[1.48vw] text-very-dark-gray leading-[2.34vw]">
        소셜라우더는 누적된 성과 지표를 분석하여 타겟에<br></br> 맞는 효과적인
        블로그 마케팅을 지원합니다.
      </h4>
      <hr className="w-full border-light-gray border-1 mt-[2.03vw]"></hr>

      <div className="flex w-[46.87vw] gap-[0.93vw]">
        <div className={DIV_STYLE}>
          <div className={CONTENT_STYLE}>
            <img
              className="w-[3.12vw] h-[3.12vw] mt-[0.78vw]"
              src={BlogImg1}
              alt="BlogImg1"
            ></img>
            <h5 className={H5_STYLE}>성공하는 광고 전략</h5>
            <p className={DESC_STYLE}>
              폭 넓은 경험을 통해 브랜드에
              <br /> 최적화된 콘텐츠 전략기획
            </p>
          </div>
        </div>

        <div className={DIV_STYLE}>
          <div className={CONTENT_STYLE}>
            <img
              className="w-[3.12vw] h-[3.12vw] mt-[0.78vw]"
              src={BlogImg2}
              alt="BlogImg2"
            ></img>
            <h5 className={H5_STYLE}>블로그 인플루언서 확보</h5>
            <p className={DESC_STYLE}>
              광고주 맞춤 타겟별 <br />
              파워인플루언서 선정
            </p>
          </div>
        </div>

        <div className={DIV_STYLE}>
          <div className={CONTENT_STYLE}>
            <img
              className="w-[3.12vw] h-[3.12vw] mt-[0.78vw]"
              src={BlogImg3}
              alt="BlogImg3"
            ></img>
            <h5 className={H5_STYLE}>재밌고 안정감있는 리뷰</h5>
            <p className={DESC_STYLE}>
              기획된 가이드를 통한
              <br /> 신뢰성 높은 리뷰 생산
            </p>
          </div>
        </div>

        <div className={DIV_STYLE}>
          <div className={CONTENT_STYLE}>
            <img
              className="w-[3.12vw] h-[3.12vw] mt-[0.78vw]"
              src={BlogImg4}
              alt="BlogImg4"
            ></img>
            <h5 className={H5_STYLE}>투명한 성과 보고</h5>
            <p className={DESC_STYLE}>
              검색 최적화를 통한 <br />
              블로그 뷰 상위 노출 및 리포트제공
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const DIV_STYLE =
  "bg-white border-solid border-2 border-light-gray2 rounded-[0.46vw] w-[15vw] h-[9.29vw] mt-[2.44vw]";
const H5_STYLE =
  "font-bold text-[0.93vw] text-very-dark-gray leading-[1.56vw] mt-[0.58vw]";
const DESC_STYLE =
  " text-dark-gray text-[0.62vw] leading-[1.17vw] tracking-tight";
const CONTENT_STYLE = "flex flex-col items-center justify-between text-center";
