import BlogExample1 from "assets/image/BlogExample1.png";
import BlogExample2 from "assets/image/BlogExample2.png";
import BlogExample3 from "assets/image/BlogExample3.png";
import BlogExample4 from "assets/image/BlogExample4.png";
import BlogExample5 from "assets/image/BlogExample5.png";
import BlogExample6 from "assets/image/BlogExample6.png";
import BlogExample7 from "assets/image/BlogExample7.png";
import BlogExample8 from "assets/image/BlogExample8.png";
import BlogExample9 from "assets/image/BlogExample9.png";
import { MultipleCarousel } from "components/common/MultipleCarousel";

export const Custom = () => {
  return (
    <div className="max-w-1200 w-[46.87vw]">
      <p className=" text-orange leading-[1.17vw] mt-[2.03vw]">
        고객의 니즈에 맞는 철저한 가이드라인과 더불어 맞춤형 타겟을 선별하여
        최고의 효율을 약속합니다.
      </p>
      <h4 className="mt-[0.31vw] font-bold tracking-tightest text-[1.48vw] text-very-dark-gray leading-[2.34vw]">
        최적의 효율을 내는 맞춤 블로그 마케팅을 제공합니다.
      </h4>
      <p className="tracking-tighter  leading-[1.17vw]">
        네이버 블로그는 신뢰성이 높은 대중적인 마케팅 채널입니다. 정보성을 갖춘
        리뷰 중심의 블로그 인플루언서 마케팅으로<br></br>우리는 최고의 팀워크로
        가장 비범한 결과를 만듭니다.
      </p>
      <div className="w-[46.87vw] mt-[2.03vw] mb-[2.03vw]">
        <MultipleCarousel
          images={CAROUSEL_IMAGES}
          amount={CAROUSEL_AMOUNT}
          size="w-[15.03vw] h-[23.43vw]"
        />
      </div>
    </div>
  );
};

const CAROUSEL_IMAGES = [
  BlogExample1,
  BlogExample2,
  BlogExample3,
  BlogExample4,
  BlogExample5,
  BlogExample6,
  BlogExample7,
  BlogExample8,
  BlogExample9,
];

const CAROUSEL_AMOUNT = 3;
