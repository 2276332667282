import LoginBackground from "assets/image/LoginBackground.png";
import LoginColoredBg from "assets/image/LoginColoredBg.png";
import LoginLogo from "assets/image/LoginLogo.png";
import LoginTextLogo from "assets/image/LoginTextLogo.png";
import CheckedRadio from "assets/svg/CheckedRadio.svg";

export const Login = () => {
  return (
    <div className="relative flex items-center justify-center w-screen h-screen bg-logo font-noto">
      <img
        src={LoginBackground}
        className="w-full h-full"
        alt="LoginBackground"
      />

      <div className="absolute bg-white w-890 h-580 rounded-10">
        <img src={LoginColoredBg} alt="LoginColoredBg" />
        <img
          src={LoginLogo}
          className="absolute -top-40 -left-60"
          alt="LoginLogo"
        />
      </div>

      <div className="absolute flex flex-col items-center ml-330 w-400 gap-15">
        <img src={LoginTextLogo} className="w-141" alt="LoginTextLogo" />
        <p className="text-14 mb-21">
          안녕하세요 쇼셜라우더 입니다. 계정을 입력해주세요.
        </p>
        <input
          placeholder="아이디를 입력해주세요"
          className="h-40 py-10 w-400 text-14 border-1 border-light-gray4 rounded-10 pl-17"
        ></input>
        <input
          placeholder="비밀번호를 입력해주세요"
          className="h-40 py-10 w-400 text-14 border-1 border-light-gray4 rounded-10 pl-17"
        ></input>

        <div className="flex gap-5 -ml-330">
          <img
            src={CheckedRadio}
            className="cursor-pointer mb-13"
            alt="CheckedRadio"
          />
          <div className="cursor-pointer w-17 h-17 rounded-4 border-1 border-light-gray5"></div>
          <p className="mb-16 text-14 text-light-gray8">자동 로그인</p>
        </div>

        <div className="font-bold text-center cursor-pointer w-400 h-50 bg-logo border-logo-border border-1 rounded-10 py-13 text-16">
          소셜라우더 로그인
        </div>

        <div className="flex gap-10 cursor-pointer text-14 text-light-gray6 mt-9">
          <p>아이디 찾기</p>
          <span>|</span>
          <p>비밀번호 찾기</p>
          <span>|</span>
          <p className="text-black">회원가입</p>
        </div>
      </div>
    </div>
  );
};
