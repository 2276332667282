import solution from "assets/image/Solution.png";
import ArrowRight from "assets/svg/ArrowRight.svg";

export const Solution = () => {
  return (
    <div className="max-w-1200 w-[46.87vw] h-[50.07vw]">
      <div className="flex">
        <div>
          <h4 className="font-bold text-[1.48vw] text-very-dark-gray leading-[2.34vw] mt-[4.29vw]">
            도달률 높은 유튜브 마케팅, 지금 시작해보세요.
          </h4>
          <p className="tracking-tighter leading-[1.17vw]">
            유튜브는 가장 대중적인 마케팅 채널입니다.<br></br> 임팩트 중심의
            쇼츠, 신뢰성 높은 리뷰 영상 등 유튜브 마케팅으로 소중한 문화
            콘텐츠를 지금 홍보해보세요!
          </p>
        </div>
      </div>
      <img
        className="w-[46.87vw] h-[31.09vw] mt-[2.03vw]"
        src={solution}
        alt="solution"
      />
    </div>
  );
};
