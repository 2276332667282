export const MobileSlogan = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-black w-390 h-372">
      <h4 className="font-bold text-center text-white leading-60 text-28">
        WE PROMISE<br></br>WITH OUR PRIDE
      </h4>
      <p className="mt-12 text-center  text-dark-gray leading-30">
        CREATE A SUCCESSFUL BRANDING<br></br>AND DIGITAL PERFORMANCE
      </p>
    </div>
  );
};
