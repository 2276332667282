import { MobileTrend } from "components/Products/MobileYoutube/MobileTrend";
import { MobileGap } from "components/common/MobileGap";
import { MobileProcess } from "./MobileProcess";
import { MobileExample } from "./MobileExample";
import { MobileSolution } from "./MobileSolution";

export const MobileYoutube = () => {
  return (
    <>
      <MobileTrend />
      <MobileGap />
      <MobileProcess />
      <MobileGap />
      <MobileExample />
      <MobileGap />
      <MobileSolution />
    </>
  );
};
